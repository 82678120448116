import { createContext } from "react";
import { TFunction } from "i18next";

import IConfiguration from "./models/IConfiguration";

export const defaultLocale: string = "fr_FR";
export const supportedLocales: Array<string> = ["fr_FR", "en_US"];

export enum Language {
    French = "fr_FR",
    English = "en_US"
}

export const LanguageContext = createContext({
    value: defaultLocale,
    set: (_: string) => {}
});

export const TranslationContext = createContext({
    t: {} as TFunction,
    get: (i18nKey: string) => i18nKey
});

export const DEFAULT_CONFIGURATION: IConfiguration = {
    id: 0,
    isLoaded: false,
    titre: "",
    reseaux_sociaux: {
        id: 0,
        facebook: undefined,
        twitter: undefined,
        youtube: undefined
    },
    boutique: {
        id: 0,
        accessible: false,
        ouverture_fr: "",
        ouverture_en: "",
        penalite_poids: 0
    },
    contact: {
        id: 0,
        default_email: "",
        responsable_publication: "",
        responsable_publication_email: ""
    },
    sponsors: [],
    adhesion: {
        id: 0,
        montant_minimum: 0
    },
    created_at: new Date(),
    updated_at: new Date()
};

export const ConfigurationContext = createContext(DEFAULT_CONFIGURATION);
