import React from "react";

export default class ReactUtils {
    public static either(state: boolean, nodeIf: React.ReactNode, nodeElse: React.ReactNode): React.ReactNode {
        if (state) {
            return nodeIf;
        }
    
        return nodeElse;
    }

    public static neither(state: boolean, nodeIf: React.ReactNode, nodeElse: React.ReactNode): React.ReactNode {
        if (state) {
            return nodeElse;
        }

        return nodeIf;
    }

    public static onlyIf(state: boolean, nodeIf: React.ReactNode): React.ReactNode {
        if (state) {
            return nodeIf;
        }

        return null;
    }
}
