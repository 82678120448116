import React, { useState, useCallback, useContext } from "react";
import { FontIcon } from "office-ui-fabric-react/lib-commonjs/Icon";
import { PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";

import css from "./store.module.css";
import constantes from "../../constantes";
import { TranslationContext, ConfigurationContext } from "../../providers";
import HttpUtils from "../../helpers/HttpUtils";
import { useFetch } from "../../helpers/hooks";
import ReactUtils from "../../helpers/ReactUtils";
import IProduct from "../../models/IProduct";
import ICartProduct from "../../models/ICartProduct";
import IIndividual from "../../models/IIndividual";
import IPaybox from "../../models/IPaybox";
import Loader from "../../components/Loader";
import Title from "../../components/Title";
import ProductCard from "../../components/ProductCard";
import FinalCart from "../../components/FinalCart";
import InformationForm, { Type } from "../../components/InformationForm";
import Paybox from "../../components/Paybox";
import CustomDialog from "../../components/CustomDialog";

enum cartStep {
    First = 1,
    Second = 2,
    Third = 3
}

export default function Store() { 
    const configurationConsumer = useContext(ConfigurationContext);
    const formatedCartProducts: Array<IProduct> = new Array<IProduct>();

    const { data } = useFetch<Array<any>>(
        `${constantes.api.origin}/uploads/countries-location.json`,
        undefined,
        []
    );

    const { data: countries } = useFetch<Array<any>>(
        `${constantes.api.origin}/uploads/countries.json`,
        undefined,
        []
    );

    const [cartProducts, setCartProducts] = useState(new Array<ICartProduct>());
    const [individual, setIndividual] = useState<IIndividual>();
    const [step, setStep] = useState(cartStep.First);
    const [success, setSuccess] = useState<boolean>(false);
    const [PBXDataForm, setPBXDataForm] = useState<IPaybox | null>(null);
    const [isWaiting, setIsWaiting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const { isLoading, data: products } = (function() {
        if (__BROWSER__) {
            return useFetch<Array<IProduct>>(
                HttpUtils.formattingSearchCriteria(
                    constantes.api.origin,
                    "produits",
                    [
                        { keywords: ["disponible"], criteria: "true" },
                        { keywords: ["_sort"], criteria: "reference:ASC" }
                    ]
                ),
                undefined,
                []
            );
        }

        return { isLoading: false, data: new Array<IProduct>(), error: null };
    })();

    const updateCart = (cartProduct: ICartProduct) => {
        const index = cartProducts.findIndex(x => x.product.id === cartProduct.product.id);

        if (index === -1) {
            // produit non présent dans le panier
            cartProducts.filter(item => { return item.quantity > 0 })
            setCartProducts([...cartProducts, cartProduct]);
        }
        else {
            setCartProducts(
                cartProducts.map((item) => {
                    return item.product.id === cartProduct.product.id ? cartProduct : item;
                }).filter(item => { return item.quantity > 0 })
            );
        }
    }

    const cartDetails = cartProducts.map((cartProduct, index) => {
        const totalProduct = cartProduct.quantity * cartProduct.product.prix;
        let idx: number = -1;

        if (cartProduct.product.modeles) {
            const ids = (cartProduct.product.id).toString().split("_");
            let idModele = ids[1];
            idx = cartProduct.product.modeles.findIndex(x => x.id === parseInt(idModele, 10))
        }

        
        return (
            <div key={ index }>
                {
                    cartProduct.quantity !== 0 && <div className={ css.inline }><p>{cartProduct.quantity + " x "}</p><p className={ css.bold }>{ cartProduct.product.nom }{ idx?.toString() !== "-1" && " (" + cartProduct.product.modeles[idx].label + ")" }</p>
                    <p style={{ float: "right" }}>{ totalProduct.toFixed(2) } €</p></div>
                }
            </div>
        );
    });

    const submitHandler = useCallback(
        function(formData: FormData, _: Type) {

            // @ts-ignore
            const individual: IIndividual = Object.fromEntries(formData);
            addIndividual(individual);

            nextHandler();
        }, [step]
    );

    const payButtonHandler = useCallback(
        async function(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
            event.preventDefault();
            
            setIsWaiting(true);

            const response = await fetch(`${constantes.api.origin}/precommandes`, {
                method: "POST",
                headers: new Headers({ "Content-Type": "application/json" }), 
                body: JSON.stringify({
                    produits: formatedData(),
                    frais_livraison: getShippingFees(individual),
                    particulier: individual
                })
            });

            setIsWaiting(false);

            if (response?.ok) {
                setSuccess(true);

                const PBXDataForm = await response.json() as IPaybox;
                setPBXDataForm(PBXDataForm);
            } else {
                const errors = (await response?.json()).message;
                setError(errors.join(" / "));

                previousHandler();
            }
        }, [step]
    );

    const previousHandler = useCallback(
        function() {
            setStep(step - 1);
            window.scrollTo(0,0);
        }, [step]
    );

    const nextHandler = useCallback(
        function() {
            setStep(step + 1);
            window.scrollTo(0,0);
        }, [step]
    );

    const addIndividual = useCallback(
        function(individual: IIndividual) {
            setIndividual(individual);
        }, []
    );

    const getTotalPrice = useCallback(
        function() {
            return cartProducts.reduce(function(acc, item) {
                return acc + item.quantity * item.product.prix;
            }, 0);
        }, [cartProducts]
    );

    const getTotalWeight = useCallback(
        function() {
            return cartProducts.reduce(function(acc, item) {
                return acc + item.quantity * item.product.poids;
            }, 0);
        }, [cartProducts]
    );

    const getShippingFees = useCallback(
        function(individual: IIndividual | undefined) {
            const country = countries?.find(item => {
                return item.name === individual?.pays;
            });

            let category;
            // on applique un pourcentage sur le poids total de la commande
            const penalite = 1 + (configurationConsumer.boutique.penalite_poids / 100);
            const weight = getTotalWeight() * penalite;

            let weightSteps;
            let shippingFees;

            data?.map(item => {
                if (item.countries && item.countries.includes(country?.code)) {
                    category = item;
                }
            });

            if (!category) 
                category = data ? data[data.length - 1] : {}

            if (category.prices)
                weightSteps = Object.keys(category.prices);

            if (weightSteps && weight < parseInt(weightSteps[0], 10))
                shippingFees = category.prices[`${weightSteps[0]}`];

            for (let i = 0; weightSteps && i < weightSteps?.length; i++) {
                if (weight > parseInt(weightSteps[i], 10) && weight < parseInt(weightSteps[i + 1], 10))
                    shippingFees = category.prices[`${weightSteps[i + 1]}`];
            }

            return shippingFees;

        }, [data, cartProducts, individual]
    );

    const formatedData = useCallback(
        function() {
            const formatedData: Array<IProduct> = new Array<IProduct>();
            
            cartProducts.map((item) => {
                if ((item.product.id).toString().includes("_")) {
                    const ids = item.product.id.toString().split("_");
                    const idProd = ids[0];
                    const idModele = ids[1];

                    let data: IProduct = {
                        id: parseInt(idProd, 10),
                        disponible: item.product.disponible,
                        nom: item.product.nom,
                        illustrations: item.product.illustrations,
                        modeles: [],
                        poids: item.product.poids,
                        prix: item.product.prix,
                        quantite: item.product.quantite,
                        created_at: item.product.created_at,
                        updated_at: item.product.updated_at
                    }

                    const indexProduct = formatedData && formatedData.findIndex(x => x.id === data.id);
                    const modele = item.product.modeles.find(modele => modele.id === parseInt(idModele, 10));

                    if (indexProduct === -1) {
                        if (modele) {
                            modele.quantite = item.quantity;
                            data.modeles.push(modele);
                        }

                        formatedData.push(data);
                    }
                    else {
                        if (modele) {
                            modele.quantite = item.quantity;
                            formatedData[indexProduct].modeles.push(modele);
                        }
                    }
                }
                else {
                    let data: IProduct = {
                        id: item.product.id,
                        disponible: item.product.disponible,
                        nom: item.product.nom,
                        illustrations: item.product.illustrations,
                        modeles: item.product.modeles,
                        poids: item.product.poids,
                        prix: item.product.prix,
                        quantite: item.quantity,
                        created_at: item.product.created_at,
                        updated_at: item.product.updated_at
                    }
                    formatedData.push(data);
                }
            });

            return formatedData;
        }, [formatedCartProducts]
    )

    const productsSized = products?.filter(prod => { return prod.modeles.length > 0 })
    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ css.wrapper }>
                        {
                            ReactUtils.onlyIf(
                                error != null,
                                <CustomDialog title={ error ?? "" } description={ translationConsumer.get("dialog.technicalError") } />
                            )
                        }

                        <Title icon={ constantes.sections.store.icon } color={ constantes.sections.store.color } text={ translationConsumer.get("header.nav.store") } />

                        { step === cartStep.First &&
                            <div className={ ["ms-Grid", "ms-sm12", "ms-md12", "ms-lg12"].join(" ") }>
                                <div className={ ["ms-Grid-col", "ms-sm12", "ms-md-12", "ms-lg12", "ms-xl8", css["product-container"]].join(" ") }>
                                    {
                                        ReactUtils.either(
                                            isLoading,
                                            <Loader className={ css["loader-container"] } />,
                                            <React.Fragment>
                                                {
                                                    products?.filter(prod => { return prod.modeles?.length === 0 })
                                                    .map(product => {
                                                        return <ProductCard key={ product.id } product={ product } cartProducts={ cartProducts } updateCart={ updateCart } /> 
                                                    })
                                                }

                                                {
                                                    productsSized?.map(item => {
                                                        return item.modeles.map((modele, idx) => {
                                                            return <ProductCard key={ idx } product={ item } cartProducts={ cartProducts } updateCart={ updateCart } modele={ modele } idx={ idx } /> 
                                                        })
                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    }
                                </div>

                                <div className={ ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg12", "ms-xl4"].join(" ") } >
                                    <p style={{ textAlign: "center" }}>
                                        <FontIcon className={ css.cart } iconName="ShoppingCartSolid" />
                                    </p>
                                    { cartDetails }
                                    { cartProducts.length !== 0 && <div className={ css.separator }/> }
                                    { cartProducts.length !== 0 && 
                                        <div className={ css.inline }>
                                            <p style={{ fontWeight: "bold" }}>TOTAL</p>
                                            <p style={{ float: "right" }}>{ getTotalPrice().toFixed(2) + " €" }</p> 
                                        </div>
                                    }
                                </div>

                                { cartProducts.length !== 0 && <div style={{ textAlign: "center" }}><PrimaryButton className={ css.nextStep } onClick={ nextHandler }>{ translationConsumer.get("store.validateStepOne") }</PrimaryButton></div> }
                            </div>
                        }

                        {
                            step === cartStep.Second &&
                            <div>
                                <div className={ css["container-writing-mode"] }>
                                    {
                                        ReactUtils.either(
                                            isWaiting,
                                            <Loader className={ css["loader-container"] } />,
                                            <InformationForm types={ [Type.Individual] } withSelectionPayment={ true } withAccountAndTaxReceipt={ false } onSubmit={ submitHandler } />
                                        )
                                    }
                                </div>
                                <div className={ css.footerForm }>
                                    <PrimaryButton className={ css.lastStep } onClick={ previousHandler }>
                                        <FontIcon className={ css.bckIcon } iconName="NavigateBack" />{ translationConsumer.get("store.backToStore") }
                                    </PrimaryButton>
                                </div>
                            </div>
                        }

                        {
                            step === cartStep.Third && 
                                <div style={{ textAlign: "center" }}>
                                    <FinalCart cartProducts={ cartProducts } shippingFees={ getShippingFees(individual) } updateCart={ updateCart } />

                                    {
                                        ReactUtils.neither(
                                            isWaiting || success,
                                            <p>
                                                <input type="submit" className="payboxBtn" onClick={ payButtonHandler } value={ translationConsumer.get("store.pay") } />
                                            </p>,
                                            <div className={ css.success }>
                                                {
                                                    ReactUtils.either(
                                                        isWaiting || PBXDataForm == null,
                                                        <Loader className={ css["loader-container"] } />,
                                                        <div className={ css["paybox-container"] }>
                                                            <Paybox paybox={ PBXDataForm as IPaybox } label={ translationConsumer.get("store.pay") } autoClick={ true } />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        )
                                    }

                                    <PrimaryButton className={ css.lastStep } onClick={ () => setStep(1) } style={{ marginTop: "20px" }} >
                                        <FontIcon className={ css.bckIcon } iconName="NavigateBack" />{ translationConsumer.get("store.backToStore") }
                                    </PrimaryButton>
                                </div>
                        }
                    </div>
                )
            }
        </TranslationContext.Consumer>
    )
}