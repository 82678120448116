import React from "react";
import _ from "underscore";

import { ConfigurationContext } from "../../providers";
import constantes from "../../constantes";
import SponsorCard from "../../components/SponsorCard";

const NUMBER_OF_CARDS_BY_LINE: number = 6;

export default function Sponsors() {
    return (
        <ConfigurationContext.Consumer>
            {
                configurationConsumer => (
                    <div className="ms-Grid">
                        {
                            _.chain(configurationConsumer.sponsors).groupBy(function(_, index) {
                                return Math.floor(index / NUMBER_OF_CARDS_BY_LINE + 1);
                            }).toArray().value().map(sponsors => {
                                return (
                                    <div className="ms-Grid-row">
                                        {
                                            // @ts-ignore
                                            sponsors.map(sponsor => {
                                                return (
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2" style={{ margin: "10px 0" }}>
                                                        <SponsorCard key={ sponsors.nom } picture={ sponsor.logo?.url && `${constantes.api.origin}${sponsor.logo?.url}` } description={ sponsor.logo?.url && (sponsor.localisation ?? "&nbsp;") || `${sponsor.nom} ${sponsor.localisation ?? "&nbsp;"}` } />
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                )
            }
        </ConfigurationContext.Consumer>
    );
}
