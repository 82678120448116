import React, { useEffect } from "react";
import i18next from "i18next";
import i18nextXHRBackend from "i18next-xhr-backend";
import { I18nextProvider } from "react-i18next";

import frFR from "./locales/fr_FR/translation.json";
import enUS from "./locales/en_US/translation.json";
import { defaultLocale, supportedLocales } from "../providers";

if (__BROWSER__) {
    window.i18next = i18next;
    i18next.use(i18nextXHRBackend);
}

i18next.init({
    backend: {
        // For all available options read the backend"s repository readme file
        loadPath: "/locales/{{lng}}/{{ns}}.json"
    },
    react: {
        // Must be false until Suspense is supported on the server side
        useSuspense: false,
        wait: true
    },
    debug: process.env.NODE_ENV === "development" && __BROWSER__,
    fallbackLng: defaultLocale,
    fallbackNS: ["translation"],
    // This option is necessary to tell i18next to try loading missing resources via
    // i18next-xhr-backend, otherwise no calls will be made if resources are defined.
    partialBundledLanguages: true,
    resources: {
        fr_FR: { translation: frFR },
        en_US: { translation: enUS }
    },
    parseMissingKeyHandler: (missing: any) => {
        if (process.env.NODE_ENV === "development" && __BROWSER__) {
            console.warn("MISSING TRANSLATION:", missing);
        }

        return missing;
    }
});

i18next.languages = supportedLocales;

const I18N: React.FC<any> = ({ children }) => {
    useEffect(() => {
        if (__BROWSER__) {
            i18next.changeLanguage(window.localStorage.getItem("i18nextLng") ?? defaultLocale);
        } else {
            i18next.changeLanguage(defaultLocale);
        }
    }, []);

    return <I18nextProvider i18n={ i18next }>{ children }</I18nextProvider>;
};

export default React.memo(I18N);
