import React from "react";

import css from "./restauration.module.css";
import { TranslationContext } from "../../providers";
import HttpUtils from "../../helpers/HttpUtils";
import constantes from "../../constantes";
import Title from "../../components/Title";
import Timeline from "../../components/Timeline";

export default function Events() {
    const defaultUrl: string = HttpUtils.formattingSearchCriteria(
        constantes.api.origin,
        "restaurations",
        [
            {keywords: ["disponible"], criteria: "true"},
            {keywords: ["_sort"], criteria: "date:DESC"}
        ]
    );

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ css.wrapper }>
                        <Title icon={ constantes.sections.restauration.icon } color={ constantes.sections.restauration.color } text={ translationConsumer.get("header.nav.restauration") } />

                        <Timeline url={ defaultUrl } withIcons={ false } />
                    </div>
                )
            }
        </TranslationContext.Consumer>
    )
}
