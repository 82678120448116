import React from "react";

import css from "./social-network.module.css";
import facebookLogoSvg from "../../assets/social/facebook.svg";
import facebookLogoPng from "../../assets/social/facebook.png";
import twitterLogoSvg from "../../assets/social/twitter.svg";
import twitterLogoPng from "../../assets/social/twitter.png";
import youtubeLogoSvg from "../../assets/social/youtube.svg";
import youtubeLogoPng from "../../assets/social/youtube.png";
import { TranslationContext, ConfigurationContext } from "../../providers";
import Reactutils from "../../helpers/ReactUtils";

export default function SocialNetwork() {
    return (
        <ConfigurationContext.Consumer>
            {
                configurationConsumer => (
                    <TranslationContext.Consumer>
                        {
                            translationConsumer => (
                                <div className={ css.wrapper }>
                                    {
                                        Reactutils.onlyIf(
                                            !!configurationConsumer.reseaux_sociaux.facebook,
                                            <a target="blank" href={ configurationConsumer.reseaux_sociaux.facebook } title={ translationConsumer.get("header.social.followOnFacebook") }>
                                                <picture>
                                                    <source type="image/svg+xml" srcSet={ facebookLogoSvg } />
                                                    <img src={ facebookLogoPng } />
                                                </picture>
                                            </a>
                                        )
                                    }

                                    {
                                        Reactutils.onlyIf(
                                            !!configurationConsumer.reseaux_sociaux.twitter,
                                            <a target="blank" href={ configurationConsumer.reseaux_sociaux.twitter } title={ translationConsumer.get("header.social.followOnTwitter") }>
                                                <picture>
                                                    <source type="image/svg+xml" srcSet={ twitterLogoSvg } />
                                                    <img src={ twitterLogoPng } />
                                                </picture>
                                            </a>
                                        )
                                    }
                                    
                                    {
                                        Reactutils.onlyIf(
                                            !!configurationConsumer.reseaux_sociaux.youtube,
                                            <a target="blank" href={ configurationConsumer.reseaux_sociaux.youtube } title={ translationConsumer.get("header.social.followOnYoutube") }>
                                                <picture>
                                                    <source type="image/svg+xml" srcSet={ youtubeLogoSvg } />
                                                    <img src={ youtubeLogoPng } />
                                                </picture>
                                            </a>
                                        )
                                    }
                                </div>
                            )
                        }
                    </TranslationContext.Consumer>
                )
            }
        </ConfigurationContext.Consumer>
    );
}
