import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Stack } from "office-ui-fabric-react/lib-commonjs/Stack";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";

import css from "./content-issue.module.css";
import image404 from "../../assets/404.png";
import { TranslationContext } from "../../providers";
import { Translation, } from "../../components/Translation";

export default function ContentIssue() {
    const history = useHistory();

    const handleEventBackHome = useCallback(
        function(_: React.FormEvent<HTMLButtonElement>): void {
            history.push("/");
        }, []
    );

    const handleEventGoBack = useCallback(
        function(_: React.FormEvent<HTMLButtonElement>): void {
            history.goBack();
        }, []
    );

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ ["ms-Grid", css.wrapper].join(" ") }>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-row">
                                <div className={ ["ms-Grid-col", "ms-lg4", css["image-container"]].join(" ") }>
                                    <img src={ image404 } style={{ height: "200px" }} />
                                </div>
                                <div className="ms-Grid-col ms-lg8">
                                    <Stack verticalFill tokens={{ childrenGap: 10 }}>
                                        <h2><Translation t={ translationConsumer.t } i18nKey="contentIssue.title" /></h2>
                                        <p><Translation t={ translationConsumer.t } i18nKey="contentIssue.description" /></p>

                                        <Stack horizontal tokens={{ childrenGap: 20 }}>
                                            <DefaultButton
                                                text={ translationConsumer.get("contentIssue.nav.backHome") }
                                                // @ts-ignore
                                                onClick={ handleEventBackHome } />
                                            <PrimaryButton
                                                text={ translationConsumer.get("contentIssue.nav.goBack") }
                                                // @ts-ignore
                                                onClick={ handleEventGoBack } />
                                        </Stack>
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </TranslationContext.Consumer>
    );
}
