import React from "react";
import { Link } from "react-router-dom";

import css from "./footer.module.css";
import { LanguageContext, TranslationContext, ConfigurationContext } from "../../providers";
import { useFetch } from "../../helpers/hooks";
import HttpUtils from "../../helpers/HttpUtils";
import ArticleUtils, { LanguageSupport } from "../../helpers/ArticleUtils";
import ReactUtils from "../../helpers/ReactUtils";
import constantes from "../../constantes";
import routes from "../../routes";
import IArticle from "../../models/IArticle";
import { Translation } from "../Translation";
import CustomDialog from "../CustomDialog";

const MAX_ARTICLES_BY_SECTION: number = 5;

interface IFooterProps {
    isMobile: boolean;
    width: number;
}

export default function Footer(props: IFooterProps) {
    const { isLoading: isLoadingForHistories, data: dataForHistories, error: errorForHistories } = useFetch<Array<IArticle>>(
        HttpUtils.formattingSearchCriteria(
            constantes.api.origin,
            "histoires",
            [
                {keywords: ["disponible"], criteria: "true"},
                {keywords: ["_sort"], criteria: "date:DESC"},
                {keywords: ["_limit"], criteria: MAX_ARTICLES_BY_SECTION.toString()}
            ]
        ),
        undefined,
        []
    );

    const { isLoading: isLoadingForRestaurations, data: dataForRestaurations, error: errorForRestaurations } = useFetch<Array<IArticle>>(
        HttpUtils.formattingSearchCriteria(
            constantes.api.origin,
            "restaurations",
            [
                {keywords: ["disponible"], criteria: "true"},
                {keywords: ["_sort"], criteria: "created_at:DESC"},
                {keywords: ["_limit"], criteria: MAX_ARTICLES_BY_SECTION.toString()}
            ]
        ),
        undefined,
        []
    );

    const todayAtMidNight: string = ArticleUtils.getMidnightDate(new Date()).toISOString();
    const { isLoading: isLoadingForEvents, data: dataForEvents, error: errorForEvents } = useFetch<Array<IArticle>>(
        HttpUtils.formattingSearchCriteria(
            constantes.api.origin,
            "evenements",
            [
                {keywords: ["disponible"], criteria: "true"},
                {keywords: ["date", "gte"], criteria: todayAtMidNight},
                {keywords: ["_sort"], criteria: "date:ASC"},
                {keywords: ["_limit"], criteria: MAX_ARTICLES_BY_SECTION.toString()}
            ]
        ),
        undefined,
        []
    );

    return (
        <ConfigurationContext.Consumer>
            {
                configurationConsumer => (
                    <TranslationContext.Consumer>
                        {
                            translationConsumer => (
                                <LanguageContext.Consumer>
                                    {
                                        languageConsumer => (
                                            <footer className={ css.wrapper }>                            
                                                <div className={ ["ms-Grid", css["sub-wrapper"]].join(" ") }>
                                                    <div className="ms-Grid-row">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                                            <h4>
                                                                <Translation t={ translationConsumer.t } i18nKey="footer.nav.latestArticlesHistoryOfTheCircuit" />
                                                            </h4>
                                                            <ul style={{ width: (props.isMobile ? `${props.width}px` : "normal") }}>
                                                                {
                                                                    ReactUtils.onlyIf(
                                                                        !isLoadingForHistories,
                                                                        ReactUtils.neither(
                                                                            errorForHistories == null,
                                                                            <CustomDialog title={ errorForHistories ?? "" } description={ translationConsumer.get("dialog.technicalError") } />,
                                                                            dataForHistories?.map(history => {
                                                                                return (function(history: IArticle, currenthistory: string) {
                                                                                    return (
                                                                                        <li key={ history.id }>
                                                                                            <Link to={ `${routes.history}/${history.id}` } title={ currenthistory }>{ currenthistory }</Link>
                                                                                        </li>
                                                                                    );
                                                                                })(history, ArticleUtils.checkLanguageSupport(languageConsumer.value, history.titre_en) == LanguageSupport.English ? history.titre_en : history.titre_fr);
                                                                            })
                                                                        )
                                                                    )
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                                            <h4>
                                                                <Translation t={ translationConsumer.t } i18nKey="footer.nav.latestArticlesAssociation" />
                                                            </h4>
                                                            <ul style={{ width: (props.isMobile ? `${props.width}px` : "normal") }}>
                                                                {
                                                                    ReactUtils.onlyIf(
                                                                        !isLoadingForRestaurations,
                                                                        ReactUtils.neither(
                                                                            errorForRestaurations == null,
                                                                            <CustomDialog title={ errorForRestaurations ?? "" } description={ translationConsumer.get("dialog.technicalError") } />,
                                                                            dataForRestaurations?.map(restauration => {
                                                                                return (function(restauration: IArticle, currentRestauration: string) {
                                                                                    return (
                                                                                        <li key={ restauration.id }>
                                                                                            <Link to={ `${routes.restauration}/${restauration.id}` } title={ currentRestauration }>{ currentRestauration }</Link>
                                                                                        </li>
                                                                                    );
                                                                                })(restauration, ArticleUtils.checkLanguageSupport(languageConsumer.value, restauration.titre_en) == LanguageSupport.English ? restauration.titre_en : restauration.titre_fr);
                                                                            })
                                                                        )
                                                                    )
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                                            <h4>
                                                                <Translation t={ translationConsumer.t } i18nKey="footer.nav.nextEventsToCome" />
                                                            </h4>

                                                            <ul style={{ width: (props.isMobile ? `${props.width}px` : "normal") }}>
                                                                {
                                                                    ReactUtils.onlyIf(
                                                                        !isLoadingForEvents,
                                                                        ReactUtils.neither(
                                                                            errorForEvents == null,
                                                                            <CustomDialog title={ errorForEvents ?? "" } description={ translationConsumer.get("dialog.technicalError") } />,
                                                                            dataForEvents?.map(event => {
                                                                                return (function(event: IArticle, currentevent: string) {
                                                                                    return (
                                                                                        <li key={ event.id }>
                                                                                            <Link to={ `${routes.events}/${event.id}` } title={ currentevent }>{ currentevent }</Link>
                                                                                        </li>
                                                                                    );
                                                                                })(event, ArticleUtils.checkLanguageSupport(languageConsumer.value, event.titre_en) == LanguageSupport.English ? event.titre_en : event.titre_fr);
                                                                            })
                                                                        )
                                                                    )
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                                            <h4>
                                                                <Translation t={ translationConsumer.t } i18nKey="footer.nav.usefulLinks" />
                                                            </h4>
                                                            <ul style={{ width: (props.isMobile ? `${props.width}px` : "normal") }}>
                                                                <li>
                                                                    <Link to="/contact" title={ translationConsumer.get("footer.nav.contactUs") }>
                                                                        <Translation t={ translationConsumer.t } i18nKey="footer.nav.contactUs" />
                                                                    </Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <nav>
                                                    <div className="ms-Grid">
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg9">
                                                                <Link to="/legal-notice" title={ translationConsumer.get("footer.legalNotice") }>
                                                                    <Translation t={ translationConsumer.t } i18nKey="footer.legalNotice" />
                                                                </Link>
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                                                {
                                                                    ReactUtils.onlyIf(
                                                                        !!configurationConsumer.titre,
                                                                        <span>@{ configurationConsumer.titre } { new Date().getFullYear() }</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </nav>
                                            </footer>
                                        )
                                    }
                                </LanguageContext.Consumer>
                            )
                        }
                    </TranslationContext.Consumer>
                )
            }
        </ConfigurationContext.Consumer>
    );
}
