import { useState, useEffect } from "react";

function checkJSONSupport(response: Response): boolean {
    if (response.headers.get("Content-Type")?.includes("application/json")) {
        return true;
    }

    return false;
}

export function useFetch<T>(input: RequestInfo, init?: RequestInit, deps?: React.DependencyList) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(
        function() {
            setIsLoading(true);
            (async function() {
                const response: Response = await fetch(input, init);
                if (response.ok) {
                    if (checkJSONSupport(response)) {
                        setData(await response.json());
                    }
                } else {
                    setError(response.statusText);
                }

                setIsLoading(false);
            })();
        }, deps
    );

    return { isLoading, data, error };
}

export function useFetchBatch<T>(entries: Array<{ input: RequestInfo, init?: RequestInit }>, deps?: React.DependencyList) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<Array<T>>(new Array<T>());
    const [error, setError] = useState<string | null>(null);

    useEffect(
        function() {
            setIsLoading(true);
            (async function() {
                const responses: Array<Response> = await Promise.all(
                    entries.map(entry => fetch(entry.input, entry?.init))
                );

                for (const response of responses) {
                    if (!response.ok) {
                        setError(response.statusText);
                        setIsLoading(false);
                        return;
                    }
                }

                const results: Array<T> = await Promise.all(
                    responses
                        .filter(response => checkJSONSupport(response))
                        .map(response => response.json())
                );

                setData(results);

                setIsLoading(false);
            })();
        }, deps
    );

    return { isLoading, data, error }
}
