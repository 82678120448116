import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { initializeIcons } from "office-ui-fabric-react/lib-commonjs/Icons";
import { Fabric } from "office-ui-fabric-react/lib-commonjs/Fabric";
import { isMobile } from "react-device-detect";

import favicon from "../shared/assets/favicon.png";
import css from "./App.module.css";
import { LanguageContext, defaultLocale, TranslationContext, ConfigurationContext, DEFAULT_CONFIGURATION } from "./providers";
import { useFetch } from "./helpers/hooks";
import ReactUtils from "./helpers/ReactUtils";
import constantes from "./constantes";
import routes from "./routes";
import IConfiguration from "./models/IConfiguration";
import Home from "./pages/Home";
import Events from "./pages/Events";
import EventDetails from "./pages/EventDetails";
import History from "./pages/History";
import HistoryDetails from "./pages/HistoryDetails";
import Restauration from "./pages/Restauration"
import RestaurationDetails from "./pages/RestaurationDetails";
import GuidedAudio from "./pages/GuidedAudio";
import Contact from "./pages/Contact";
import LegalNotice from "./pages/LegalNotice";
import ContentIssue from "./pages/ContentIssue";
import Search from "./pages/Search";
import Store from "./pages/Store";
import Sponsors from "./pages/Sponsors";
import Membership from "./pages/Membership";
import PaymentVerification from "./pages/PaymentVerification";
import Header from "./components/Header";
import Footer from "./components/Footer";

// Load once icons for Fluent UI library
initializeIcons(undefined, { disableWarnings: true });

function Body() {
    return (
        <ConfigurationContext.Consumer>
            {
                configurationConsumer => (
                    <Fabric className={ ["app", css.wrapper].join(" ") }>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-lg1 ms-hiddenMdDown"></div>
                                <div className={ ["ms-Grid-col", "ms-md12", "ms-lg10", css.bloc].join(" ") }>
                                    <Helmet
                                        defaultTitle={ configurationConsumer.titre }
                                        link={[{ rel: "icon", type: "image/png", href: favicon }]}
                                    />

                                    <div className="ms-Grid">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-lg12">
                                                <Header />
                                            </div>
                                            <div className="ms-Grid-col ms-lg12" style={{ width: "100%" }}>
                                                <section>
                                                    <Switch>
                                                        <Route exact path={ routes.home } component={ Home } />
                                                        <Route exact path={ routes.events } component={ Events } />
                                                        <Route path={ routes.event_details } component={ EventDetails } />
                                                        <Route exact path={ routes.history } component={ History } />
                                                        <Route path={ routes.history_details } component={ HistoryDetails } />
                                                        <Route exact path={ routes.restauration } component={ Restauration } />
                                                        <Route path={ routes.restauration_details } component={ RestaurationDetails } />
                                                        <Route exact path={ routes.audio_tour } component={ GuidedAudio } />

                                                        {
                                                            ReactUtils.onlyIf(
                                                                configurationConsumer.boutique.accessible,
                                                                <Route exact path={ routes.store } component={ Store } />
                                                            )
                                                        }

                                                        <Route exact path={ routes.contact } component={ Contact } />
                                                        <Route exact path={ routes.sponsors } component={ Sponsors } />
                                                        <Route exact path={ routes.legalNotice } component={ LegalNotice } />
                                                        <Route exact path={ routes.membership } component={ Membership } />
                                                        <Route path={ routes.successPaymentVerification } component={ PaymentVerification } />
                                                        <Route path={ routes.failurePaymentVerification } component={ PaymentVerification } />
                                                        <Route path={ routes.search } render={props => (
                                                            <Search key={ props.match.params.keywords } { ...props } />
                                                        )} />
                                                        <Route component={ ContentIssue } />
                                                    </Switch>
                                                </section>
                                            </div>
                                            <div className="ms-Grid-col ms-lg12">
                                                {
                                                    __BROWSER__
                                                        && <Footer isMobile={ isMobile } width={ window.innerWidth - 25 } />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-lg1 ms-hiddenMdDown"></div>
                            </div>
                        </div>
                    </Fabric>
                )
            }
        </ConfigurationContext.Consumer>
    );
}

export default function App() {
    const [locale, setLocale] = useState({
        value: __BROWSER__ ? window.i18next.language ?? defaultLocale : defaultLocale,
        set: (language: string) => {
            if (__BROWSER__) {
                window.localStorage.setItem("i18nextLng", language);
                window.i18next.changeLanguage(language);
            }

            let oldLocale = locale;
            oldLocale.value = language;

            setLocale(oldLocale);
        }
    });

    const [translation] = useState({
        t: useTranslation().t,
        get: (i18nKey: string) => {
            return translation.t(i18nKey);
        }
    });

    const [configuration, setConfiguration] = useState(DEFAULT_CONFIGURATION);

    const { isLoading, data: newConfiguration, error } = (function() {
        if (__BROWSER__) {
            return useFetch<IConfiguration>(
                `${constantes.api.origin}/parametrage`,
                undefined,
                []
            );
        }

        return { isLoading: false, data: new Object() as IConfiguration, error: null };
    })();
    useEffect(
        function() {
            if (!isLoading && error == null && newConfiguration != null) {
                newConfiguration.isLoaded = true;
                setConfiguration(newConfiguration as IConfiguration);
            }
        }, [isLoading]
    );

    useEffect(
        function() {
            if (__BROWSER__) {
                // Little trick to load once
                const fn = (language: string) => {
                    window.i18next.off("languageChanged", fn);

                    locale.set(language);
                };

                window.i18next.on("languageChanged", fn);
            }
        }, []
    );

    return (
        <ConfigurationContext.Provider value={ configuration }>
            <TranslationContext.Provider value={ translation }>
                <LanguageContext.Provider value={ locale }>
                    <Body />
                </LanguageContext.Provider>
            </TranslationContext.Provider>
        </ConfigurationContext.Provider>
    );
}
