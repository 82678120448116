import { Locale } from "./types";

export const ActionTypes = {
    SETLOCALE: "app/set-locale",
};

export function setLocale(locale: Locale) {
    return {
        type: ActionTypes.SETLOCALE,
        payload: locale,
    }
}
