import React, { useEffect, useCallback, useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";

import successSvg from "../../assets/success.svg";
import errorSvg from "../../assets/error.svg";
import css from "./payment-verification.module.css";
import routes from "../../routes";
import constantes from "../../constantes";
import ReactUtils from "../../helpers/ReactUtils";
import { TranslationContext } from "../../providers";
import Loader from "../../components/Loader";

export default function AsynchonousPaymentVerification(props: any) {
    const translationConsumer = useContext(TranslationContext);
    const history = useHistory();
    const [isWaiting, setIsWaiting] = useState<boolean>(true);
    const [message, setMessage] = useState<React.ReactElement>();

    const generateSuccessMessage = useCallback(
        function(messageBody: React.ReactElement): void {
            setMessage(
                <div className={ css["response-container"] }>
                    <img src={ successSvg } />

                    <hr />

                    <p>{ messageBody }</p>

                    <Link to={ routes.home } title={ translationConsumer.get("payment.backToHomePage") }>{ translationConsumer.get("payment.backToHomePage") }</Link>
                </div>
            );
        }, []
    );

    const generateErrorMessage = useCallback(
        function(messageBody: React.ReactElement): void {
            setMessage(
                <div className={ css["response-container"] }>
                    <img src={ errorSvg } />

                    <hr />

                    <p>{ messageBody }</p>

                    <Link to={ routes.home } title={ translationConsumer.get("payment.backToHomePage") }>{ translationConsumer.get("payment.backToHomePage") }</Link>
                </div>
            );
        }, []
    );

    const successHandler = useCallback(
        async function(reference: string | null, statusCode: string | null): Promise<void> {
            if (statusCode == "99999") {
                generateSuccessMessage(<span>{ translationConsumer.get("payment.codes.waitingForValidation") }</span>);
                return;
            }

            const response = await fetch(`${constantes.api.origin}/paiements/ok?reference=${reference}`, { method: "POST" });

            setIsWaiting(false);

            if (response.ok) {
                const paymentType = await response.json();

                generateSuccessMessage(
                    paymentType.type == "adhesion"
                        && (
                            <React.Fragment>
                                <span>Votre demande d'adhésion a été validée.</span><br />
                                <span>{ translationConsumer.get("payment.confirmationEmail") }</span>
                            </React.Fragment>
                        )
                        || (
                            <React.Fragment>
                                <span>Votre commande a été bien prise en compte.</span><br />
                                <span>{ translationConsumer.get("payment.confirmationEmail") }</span>
                            </React.Fragment>
                        )
                );
            } else {
                const errors = (await response?.json()).message;

                generateErrorMessage(
                    <ul>
                        {
                            // @ts-ignore
                            errors.map(error => <li key={ error }>{ error }</li>)
                        }
                    </ul>
                );
            }
        }, []
    );

    const detectError = useCallback(
        function(errorCode: string | null): string {
            if (!errorCode || isNaN(parseInt(errorCode, 10))) {
                return translationConsumer.get("payment.codes.errors.UNKNOWN_ERROR");
            } else {
                const code = parseInt(errorCode, 10);

                if (code == 1) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_1");
                } else if (code == 3) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_3");
                } else if (code == 4) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_4");
                } else if (code == 6) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_6");
                } else if (code == 8) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_8");
                } else if (code == 9) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_9");
                } else if (code == 10) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_10");
                } else if (code == 11) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_11");
                } else if (code == 15) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_15");
                } else if (code == 16) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_16");
                } else if (code == 21) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_21");
                } else if (code == 29) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_29");
                } else if (code == 30) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_30");
                } else if (code == 31 || code == 32) {
                    return translationConsumer.get("payment.codes.errors.UNKNOWN_ERROR");
                } else if (code == 33) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_33");
                } else if (code == 40) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_40");
                } else if (code >= 100 && code < 200) {
                    return translationConsumer.get("payment.codes.errors.ERROR_CODE_1XX");
                } else {
                    return translationConsumer.get("payment.codes.errors.UNKNOWN_ERROR");
                }
            }
        }, []
    );

    const failureHandler = useCallback(
        function(reference: string | null, errorCode: string | null): void {
            setTimeout(async function(): Promise<void> {
                const response = await fetch(`${constantes.api.origin}/paiements/ko?reference=${reference}`, { method: "POST" });

                setIsWaiting(false);

                if (response.ok) {
                    const paymentType = await response.json();

                    generateErrorMessage(
                        paymentType.type == "adhesion"
                            && (
                                <React.Fragment>
                                    <span>{ detectError(errorCode) }</span><br />
                                    <span>{ translationConsumer.get("payment.codes.errors.messageForMembership") }</span>
                                </React.Fragment>
                            )
                            || (
                                <React.Fragment>
                                    <span>{ detectError(errorCode) }</span><br />
                                    <span>{ translationConsumer.get("payment.codes.errors.messageForCommand") }</span>
                                </React.Fragment>
                            )
                    );
                } else {
                    const errors = (await response?.json()).message;

                    generateErrorMessage(
                        <ul>
                            {
                                // @ts-ignore
                                errors.map(error => <li key={ error }>{ error }</li>)
                            }
                        </ul>
                    );
                }
            }, 1000);
        }, []
    );

    useEffect(
        function() {
            if (__BROWSER__) {
                const reference: string | null = new URLSearchParams(window.location.search).get("Ref");
                const statusCode: string | null = new URLSearchParams(window.location.search).get("Erreur");

                if (props.match.path.includes("success")) {
                    successHandler(reference, statusCode);
                } else if (props.match.path.includes("failure")) {
                    failureHandler(reference, statusCode);
                } else {
                    history.push("/"); // redirection de force vers la page d'accueil
                }
            }
        }, []
    );

    return (
        <div className={ css.wrapper }>
            {
                ReactUtils.either(
                    isWaiting || message == null,
                    <Loader className={ css["loader-container"] } />,
                    <div className={ css.container }>
                        {
                            message
                        }
                    </div>
                )
            }
        </div>
    );
}
