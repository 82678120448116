import React, { useRef, useEffect } from "react";

import IPaybox from "../../models/IPaybox";

interface IPayboxProps {
    paybox: IPaybox;
    label: string;
    autoClick?: boolean;
}

export default function Paybox(props: IPayboxProps) {
    const submitElement = useRef(null);

    useEffect(function () {
        if (props.autoClick) {
            (submitElement.current as unknown as HTMLElement).click();
        }
    }, []);

    return (
        <form method="POST" action={props.paybox.SERVER} target="iframePayment">
            <input type="hidden" name="PBX_SITE" value={props.paybox.PBX_SITE} />
            <input type="hidden" name="PBX_RANG" value={props.paybox.PBX_RANG} />
            <input type="hidden" name="PBX_IDENTIFIANT" value={props.paybox.PBX_IDENTIFIANT} />
            <input type="hidden" name="PBX_TOTAL" value={props.paybox.PBX_TOTAL} />
            <input type="hidden" name="PBX_DEVISE" value={props.paybox.PBX_DEVISE} />
            <input type="hidden" name="PBX_CMD" value={props.paybox.PBX_CMD} />
            <input type="hidden" name="PBX_PORTEUR" value={props.paybox.PBX_PORTEUR} />
            <input type="hidden" name="PBX_REPONDRE_A" value={props.paybox.PBX_REPONDRE_A} />
            <input type="hidden" name="PBX_RETOUR" value={props.paybox.PBX_RETOUR} />
            <input type="hidden" name="PBX_EFFECTUE" value={props.paybox.PBX_EFFECTUE} />
            <input type="hidden" name="PBX_ANNULE" value={props.paybox.PBX_ANNULE} />
            <input type="hidden" name="PBX_REFUSE" value={props.paybox.PBX_REFUSE} />
            <input type="hidden" name="PBX_HASH" value={props.paybox.PBX_HASH} />
            <input type="hidden" name="PBX_TIME" value={props.paybox.PBX_TIME} />
            <input type="hidden" name="PBX_SHOPPINGCART" value={props.paybox.PBX_SHOPPINGCART} />
            <input type="hidden" name="PBX_BILLING" value={props.paybox.PBX_BILLING} />
            <input
                type="hidden"
                name="PBX_SOUHAITAUTHENT"
                value={props.paybox.PBX_SOUHAITAUTHENT}
            />
            <input type="hidden" name="PBX_HMAC" value={props.paybox.PBX_HMAC} />
            <input className="payboxBtn" ref={submitElement} type="submit" value={props.label} />
        </form>
    );
}
