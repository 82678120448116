import React, { createRef, useEffect, useState } from "react";
import { Card, CardBody, CardFooter } from "react-simple-card";
import { FontIcon } from "office-ui-fabric-react/lib-commonjs/Icon";
import Lightbox from "react-image-lightbox";

import css from "./product-card.module.css";
import constantes from "../../constantes";
import { TranslationContext } from "../../providers";
import IProduct from "../../models/IProduct";
import ICartProduct from "../../models/ICartProduct";
import ArticleCarousel from "../ArticleCarousel";

interface IProductCardProps {
    product: IProduct;
    cartProducts: Array<ICartProduct>;
    updateCart: Function;
    modele?: { id: number, label: string, quantite: number };
    idx?: number;
}

export default function ProductCard(props: IProductCardProps) {
    const containerRef: React.RefObject<HTMLDivElement> = createRef();

    const [cartProduct, setCartProduct] = useState({ product: props.product, quantity: 0 });
    const [selectedPicture, setSelectedPicture] = useState<string | undefined>(undefined);

    let currentItem: Array<ICartProduct>;
    let product: IProduct;

    const pictures = props.product.illustrations.map(item => {
        let picture = new Image();
        picture.src = `${constantes.api.origin}${item.url}`
        return picture
    });

    useEffect(
        function() {
            Array.from(
                containerRef.current?.getElementsByTagName("li") ?? []
            ).forEach(imageNode => {
                imageNode.addEventListener("click", function(event: MouseEvent): void {
                    const img = (event?.target as unknown as HTMLImageElement)?.querySelector("img");
                    setSelectedPicture(img?.src);
                });
            });
        }, []
    );

    const handleChangeValue = (operation: string, quantite: number) => {
        if (operation === "minus")
            quantite -= 1
        else if (operation === "plus")
            quantite += 1

        if (props.modele) {
            product = {
                id: `${props.product.id}_${props.modele.id}`,
                nom: props.product.nom,
                disponible: props.product.disponible,
                illustrations: props.product.illustrations,
                modeles: props.product.modeles,
                poids: props.product.poids,
                quantite: props.product.quantite,
                prix: props.product.prix,
                created_at: props.product.created_at,
                updated_at: props.product.updated_at
            }

            const cartProduct = { product: product, quantity: quantite }
            setCartProduct(cartProduct);
            props.updateCart(cartProduct);
        }
        else {
            const cartProduct = { product: props.product, quantity: quantite }
            setCartProduct(cartProduct);
            props.updateCart(cartProduct);
        }
    };

    if (props.modele) {
        currentItem = props.cartProducts.filter(item => { return item.product.id === `${props.product.id}_${props.modele?.id}` })
    } else {
        currentItem = props.cartProducts.filter(item => { return item.product.id === props.product.id })
    }

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ css.wrapper }>
                        <div className={ ["ms-Grid-col", "ms-sm12", "ms-md6", "ms-lg3", "ms-xl4", "ms-xxl3"].join(" ") } style={{ display: "inline-block", height: "350px", marginTop: "10px" }}>
                            {
                                !props.modele ?
                                    <Card key={ props.product.id } className={ props.product.quantite > 0 ? css.card : [css.card, css.noStock].join(" ") }>
                                        <div className={ css.slider } ref={ containerRef }>
                                            <ArticleCarousel
                                                isMobile={ true }
                                                pictures={ pictures }
                                                showArrows={ true }
                                                backgroundColor="#d3d2cd"
                                            />

                                            {
                                                selectedPicture
                                                    && <Lightbox mainSrc={ selectedPicture } onCloseRequest={ () => setSelectedPicture(undefined) } />
                                            }
                                        </div>
                                        <CardBody style={{ paddingTop: 0, height: "20%" }}>
                                            <b><p style={{ textAlign: "center" }}>{ props.product.nom }</p></b>
                                            <p style={{ textAlign: "center" }}>{ props.product.prix } €</p>
                                        </CardBody>
                                        <CardFooter style={{ height: "15%" }}>
                                            <div style={{ textAlign: "center", width: "100%" }}>
                                                {
                                                    props.product.quantite > 0
                                                        ? (
                                                            <React.Fragment>
                                                                {
                                                                    currentItem[0]?.quantity > 0 && props.product.quantite > 0 ?
                                                                    <button className={ [css.hiddenBackground, css.btnMinus].join(" ") }><FontIcon iconName="Remove" className={ [css.inline, css.manageCart, css.btnMinus].join(" ") } onClick={ () => handleChangeValue("minus", currentItem[0] ? currentItem[0].quantity : 0) }/></button> :
                                                                    <button className={ [css.hiddenBackground, css.btnMinus].join(" ") } disabled><FontIcon iconName="Remove" className={ [css.inline, css.manageCart, css.btnMinus].join(" ") } /></button>
                                                                }
                                                                { currentItem[0] ? currentItem[0].quantity : 0 }
                                                                {
                                                                    (!currentItem[0] || currentItem[0]?.quantity < props.product.quantite) && props.product.quantite > 0 ?
                                                                    <button className={ css.hiddenBackground }><FontIcon iconName="Add" className={ [css.inline, css.manageCart, css.btnPlus].join(" ") } onClick={ () => handleChangeValue("plus", currentItem[0] ? currentItem[0].quantity : 0) } /></button> :
                                                                    <button className={ css.hiddenBackground } disabled><FontIcon iconName="Add" className={ [css.inline, css.manageCart, css.btnPlus].join(" ") } /></button>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                        : <span className={ css.noStockLabel }>{ translationConsumer.get("store.outOfStock") }</span>
                                                }
                                            </div>
                                        </CardFooter>
                                    </Card>
                                :
                                <Card key={ `${props.product.id}_${props.modele.id}` } className={ props.modele.quantite > 0 ? css.card : [css.card, css.noStock].join(" ") }>
                                    <div className={ css.slider }>
                                        <ArticleCarousel
                                            isMobile={ true }
                                            pictures={ pictures }
                                            showArrows={ true }
                                            backgroundColor="#d3d2cd" />
                                    </div>
                                    <CardBody style={{ paddingTop: 0, height: "20%" }}>
                                        <b>
                                            <p style={{ textAlign: "center", lineHeight: "22px" }}>
                                                <span>{ props.product.nom }</span>&nbsp;
                                                <span className={ css.label }>{ props.modele.label }</span>
                                            </p>
                                        </b>
                                        <p style={{ textAlign: "center" }}>{ props.product.prix } €</p>
                                    </CardBody>
                                    <CardFooter style={{ height: "15%" }}>
                                        <div style={{ textAlign: "center", width: "100%" }}>
                                            {
                                                props.modele.quantite > 0
                                                    ? (
                                                        <React.Fragment>
                                                            {
                                                                currentItem[0]?.quantity > 0 && props.modele.quantite > 0 ?
                                                                <button className={ [css.hiddenBackground, css.btnMinus].join(" ") } onClick={ () => handleChangeValue("minus", currentItem[0] ? currentItem[0].quantity : 0) }>
                                                                    <FontIcon iconName="Remove" className={ [css.inline, css.manageCart, css.btnMinus].join(" ") } />
                                                                </button> :
                                                                <button className={ [css.hiddenBackground, css.btnMinus].join(" ") } disabled><FontIcon iconName="Remove" className={ [css.inline, css.manageCart, css.btnMinus].join(" ") } /></button>
                                                            }
                                                            { currentItem[0] ? currentItem[0].quantity : 0 }
                                                            {
                                                                (!currentItem[0] || currentItem[0]?.quantity < props.modele.quantite) && props.modele.quantite > 0 ?
                                                                <button className={ css.hiddenBackground } onClick={ () => handleChangeValue("plus", currentItem[0] ? currentItem[0].quantity : 0) }>
                                                                    <FontIcon iconName="Add" className={ [css.inline, css.manageCart, css.btnPlus].join(" ") } />
                                                                </button> :
                                                                <button className={ css.hiddenBackground } disabled><FontIcon iconName="Add" className={ [css.inline, css.manageCart, css.btnPlus].join(" ") } /></button>
                                                            }
                                                        </React.Fragment>
                                                    )
                                                    : <span className={ css.noStockLabel }>{ translationConsumer.get("store.outOfStock") }</span>
                                            }
                                    </div>
                                    </CardFooter>
                                </Card>
                            }

                        </div>
                    </div>
                )
            }
        </TranslationContext.Consumer>
    )
}
