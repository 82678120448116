import React, { useContext } from "react";

import css from "./history-details.module.css";
import ReactUtils from "../../helpers/ReactUtils";
import { useFetch } from "../../helpers/hooks";
import { TranslationContext } from "../../providers";
import constantes from "../../constantes";
import IHistory from "../../models/IHistory";
import Loader from "../../components/Loader";
import CustomDialog from "../../components/CustomDialog";
import DetailsHistory from "../../components/HistoryDetails";

export default function EventDetails(props: any) {
    const translationConsumer = useContext(TranslationContext);
    const { isLoading, data: histoireArticle, error } = (function() {
        if (__BROWSER__) {
            return useFetch<IHistory>(
                `${constantes.api.origin}/histoires/${props.match.params.id}`,
                undefined,
                [props.match.params.id]
            );
        }

        return { isLoading: false, data: new Object() as IHistory, error: null };
    })();

    return (
        <div className={ css.wrapper }>
            {
                ReactUtils.either(
                    isLoading,
                    <Loader className={ css["loader-container"] } />,
                    ReactUtils.neither(
                        error == null,
                        <CustomDialog title={ error ?? "" } description={ translationConsumer.get("dialog.technicalError") } />,
                        ReactUtils.onlyIf(
                            histoireArticle != null,
                            <DetailsHistory history={ histoireArticle as IHistory } />
                        )
                    )
                )
            }
        </div>
    );
}
