import React from "react";
import moment from "moment";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import css from "./audio.module.css";
import ArticleUtils, { LanguageSupport } from "../../helpers/ArticleUtils";
import { LanguageContext, TranslationContext } from "../../providers";
import constantes from "../../constantes";
import IVisit from "../../models/IVisit";
import { Translation } from "../../components/Translation";
import ReactUtils from "helpers/ReactUtils";

interface IAudioProps {
    visit: IVisit;
}

export default function Audio(props: IAudioProps) {
    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <LanguageContext.Consumer>
                        {
                            languageConsumer => (
                                <div className={ css.wrapper }>
                                    <div key={ props.visit.id } className={ ["ms-Grid", css.container].join(" ") }>
                                        <div className={ ["ms-Grid-row", css["full-height"]].join(" ") }>
                                            <div className={ ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg3", css["full-height"]].join(" ") }>
                                                <div className={ [css["thumbnail-container"], css["full-height"]].join(" ") }>
                                                    {
                                                        props.visit.favoris && <i className={ ["ms-Icon", "ms-Icon--HeartFill", css.favorite].join(" ") } title={ translationConsumer.get("general.favorite") } />
                                                    }

                                                    {
                                                        ReactUtils.onlyIf(
                                                            props.visit.vignette?.url != null,
                                                            <img src={ `${constantes.api.origin}${props.visit.vignette?.url}` } className={ props.visit.vignette?.height ?? 0 > 100 ? css.big : css.small } />
                                                        )
                                                    }
                                                </div>
                                            </div>

                                            <div className={ ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg5", css["full-height"]].join(" ") }>
                                                <div className="ms-Grid">
                                                    <div className="ms-Grid-row" style={{ padding: "0 8px" }}>
                                                        {
                                                            ReactUtils.either(
                                                                props.visit.identificateur == null,
                                                                <div className={ ["ms-Grid-col", "ms-sm3", "ms-md3", "ms-lg3", css["identifier-container"], css["full-height"]].join(" ") }>
                                                                    <span className={ [css.identifier, css["missing-identifier"]].join(" ") }>
                                                                        <Translation t={ translationConsumer.t } i18nKey="audioTour.missingIdentifier" />
                                                                    </span>
                                                                </div>,
                                                                <div className={ ["ms-Grid-col", "ms-sm3", "ms-md3", "ms-lg3", css["identifier-container"], css["full-height"]].join(" ") }>
                                                                    <div className={ css.pastille } style={{ backgroundColor: props.visit.identificateur }}>
                                                                        <span className={ [css.identifier, css["identifier-present"]].join(" ") }>
                                                                            <Translation t={ translationConsumer.t } i18nKey="audioTour.identifierPresent" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        <div className={ ["ms-Grid-col", "ms-sm9", "ms-md9", "ms-lg9", css["description-container"], css["full-height"]].join(" ") }>
                                                            <h5 className={ css.date }>{ moment(props.visit.created_at).locale(languageConsumer.value).format("DD MMM YYYY") }</h5>
                                                            <h3 className={ css.title }>{ ArticleUtils.checkLanguageSupport(languageConsumer.value, props.visit.titre_en) == LanguageSupport.English ? props.visit.titre_en : props.visit.titre_fr }</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={ ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg4", css["player-container"], css["full-height"]].join(" ") }>
                                                <AudioPlayer src={ `${constantes.api.origin}${props.visit.piste_audio.url}` } style={{ display: "table-cell", verticalAlign: "middle" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </LanguageContext.Consumer>
                )
            }
        </TranslationContext.Consumer>
    );
}
