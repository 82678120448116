export default class HttpUtils {
    public static formattingSearchCriteria(origin: string, section: string, parts: Array<{keywords: Array<string>, criteria: string}>, separator: string = "_"): string {
        const queries: string = parts?.map(part => {
            return part?.keywords?.join(separator)?.concat("=")?.concat(encodeURI(part?.criteria));
        })?.join("&");

        return origin?.concat("/")?.concat(section)?.concat("?").concat(queries);
    }

    public static async fetch<T>(url: string): Promise<T> {
        const response: Response = await fetch(url);
        const result: T = await response.json() as T;

        return result;
    }

    public static async batchFetch<T>(urls: Array<string>): Promise<Array<T>> {
        const responses: Array<Response> = await Promise.all(
            urls?.map(url => fetch(url))
        );

        if (responses?.length) {
            const results: Array<T> = await Promise.all(
                responses.map(response => response.json())
            );

            return results;
        }

        return new Array<T>();
    }
}
