import React from "react";
import { TFunction } from "i18next";
import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib-commonjs/MessageBar";
import moment from "moment";

import { Translation } from "../components/Translation";
import IArticle from "../models/IArticle";

interface IWarningObsoleteProps {
    children?: React.ReactNode;
    resetChoice?: () => void;
    t: TFunction;
}

export function WarningObsoleteMessageBar(props: IWarningObsoleteProps) {
    return (
        <MessageBar
            messageBarType={ MessageBarType.warning }
            isMultiline={ false }
            onDismiss={ props.resetChoice }
            dismissButtonAriaLabel="Close">
            <Translation t={ props.t } i18nKey="general.obsoleteArticle" />
        </MessageBar>
    );
}

export enum LanguageSupport {
    French,
    English,
    NotSupported
}

export default class ArticleUtils {
    public static checkLanguageSupport(locale: string, value: string): LanguageSupport {
        if (locale.startsWith("en")) {
            if (value?.length) {
                return LanguageSupport.English;
            } else {
                return LanguageSupport.NotSupported;
            }
        } else {
            return LanguageSupport.French;
        }
    }

    public static isSupported(locale: string, value: string): boolean {
        return ArticleUtils.checkLanguageSupport(locale, value) != LanguageSupport.NotSupported;
    }

    public static getMidnightDate(date: Date): Date {
        return moment(date).startOf("days").toDate();
    }

    public static sortByDate(articles: Array<IArticle>, fnGetDate: (article: IArticle) => Date): Array<IArticle> {
        return articles.sort(function(articleA: IArticle, articleB: IArticle) {
            const dateA: Date = fnGetDate(articleA);
            const dateB: Date = fnGetDate(articleB);

            if (dateA < dateB) return -1;
            else if (dateA > dateB) return 1;
            else return 0;
        });
    }

    public static reverseByDate(articles: Array<IArticle>, fnGetDate: (article: IArticle) => Date): Array<IArticle> {
        return ArticleUtils.sortByDate(articles, fnGetDate).reverse();
    }
}
