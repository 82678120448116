import React, { useState, useCallback } from "react";
import Slider from "react-slick";
import { Carousel } from "react-responsive-carousel";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";

import css from "./article-carousel.module.css";

interface IArticleCarouselProps {
    pictures: Array<HTMLImageElement>;
    width?: number;
    isMobile: boolean;
    showArrows?: boolean;
    backgroundColor?: string;
}

export default function ArticleCarousel(props: IArticleCarouselProps) {
    const DEFAULT_SETTINGS = {
        dots: props.showArrows ?? false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
    };

    // little trick to resize dynamically the dots slick (bug library)
    const [isLoaded, setIsLoaded] = useState(false);
    const dynamicallyResize = useCallback(
        function() {
            if (!isLoaded) {
                setIsLoaded(true);

                if (props.isMobile) {
                    Array.from(
                        // @ts-ignore
                        document.querySelectorAll("li.slide") as HTMLCollectionOf<HTMLElement>
                    ).forEach(el => el.setAttribute("style", `background-color: ${props.backgroundColor ?? "#FFF"} !important`));
                } else {
                    Array.from(
                        // @ts-ignore
                        document.querySelectorAll(".slick-dots") as HTMLCollectionOf<HTMLElement>
                    ).forEach(el => el.setAttribute("style", "bottom: inherit !important"));
                }
            }
        }, [isLoaded]
    );

    const [currentSlide, setCurrentSlide] = useState(1);
    const afterChangeHandler = useCallback(
        function(index) {
            setCurrentSlide(index + 1);
        }, []
    );

    const [selectedPicture, setSelectedPicture] = useState<string | null>(null);
    const imageClickHandler = useCallback(
        function(event: React.MouseEvent<HTMLImageElement, MouseEvent>): void {
            setSelectedPicture((event?.target as unknown as HTMLImageElement)?.src);
        }, []
    );

    return (
        <div className={ [css.wrapper].concat(props.isMobile ? css["is-mobile"] : "").join(" ") }>
            {
                props.isMobile
                    && (
                        props.pictures.length > 0
                            && (
                                <Carousel
                                    showArrows={ DEFAULT_SETTINGS.dots }
                                    showThumbs={ false }
                                    showIndicators={ false }
                                    showStatus={ false }
                                    width={ props.width }
                                    swipeable={ false }
                                    stopOnHover={ false }
                                    interval={ DEFAULT_SETTINGS.autoplaySpeed }
                                    transitionTime={ DEFAULT_SETTINGS.speed }
                                    infiniteLoop={ DEFAULT_SETTINGS.infinite }
                                    autoPlay={ DEFAULT_SETTINGS.autoplay }
                                >
                                    {
                                        props.pictures.map(picture => {
                                            return <img onClick={ imageClickHandler } key={ picture.src } src={ picture.src } onLoad={ dynamicallyResize } />
                                        })
                                    }
                                </Carousel>
                            )
                    )
                    || (
                        props.pictures.length > 0
                            && (
                                <div className={ css["slider-container"] }>
                                    <Slider { ...DEFAULT_SETTINGS } className={ css.slider } afterChange={ afterChangeHandler }>
                                        {
                                            props.pictures.map(picture => {
                                                return <img onClick={ imageClickHandler } key={ picture.src } src={ picture.src } onLoad={ dynamicallyResize } />
                                            })
                                        }
                                    </Slider>

                                    <div className={ css.pagination }>{ `${currentSlide} / ${props.pictures.length}` }</div>
                                </div>
                            )
                    )
            }

            {
                selectedPicture
                    && <Lightbox mainSrc={ selectedPicture } onCloseRequest={ () => setSelectedPicture(null) } />
            }
        </div>
    );
}
