import React from "react";

import css from "./sponsor-card.module.css"

interface ICardProps {
    picture?: string;
    description?: string;
}

export default function SponsorCard(props: ICardProps) {
    return (
        <div className={ css.wrapper }>
            <div className={ css["full-card"] }>
                <div className={ css.thumbnail }>
                    {
                        props.picture
                            && <img src={ props.picture } />
                            || <div className={ css["no-picture"] } />
                    }
                </div>
                <div className={ css.description } dangerouslySetInnerHTML={{ __html: props.description ?? "" }} />
            </div>
        </div>
    );
}
