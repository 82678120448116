import React, { useContext, useCallback, useState, useEffect, createRef } from "react";
import moment from "moment";
import { Stack } from "office-ui-fabric-react/lib-commonjs/Stack";
import { Separator } from "office-ui-fabric-react/lib-commonjs/Separator";
import "moment/locale/fr";
import ReactMarkdown from "react-markdown";
import { isMobile } from "react-device-detect";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

import pdfLogo from "../../assets/pdf.png";
import css from "./event-details.module.css";
import { LanguageContext, TranslationContext } from "../../providers";
import ArticleUtils, { LanguageSupport, WarningObsoleteMessageBar } from "../../helpers/ArticleUtils";
import IEvent from "../../models/IEvent";
import IHistory from "../../models/IHistory";
import Navigation from "../Navigation";
import ArticleCarousel from "../ArticleCarousel";

interface IEventDetailsProps {
    event: IEvent
}

export default function EventDetails(props: IEventDetailsProps) {
    const languageConsumer = useContext(LanguageContext);
    const translationConsumer = useContext(TranslationContext);

    const containerRef: React.RefObject<HTMLDivElement> = createRef();
    const [pictures, setPictures] = useState(new Array<HTMLImageElement>());
    const [selectedPicture, setSelectedPicture] = useState<string | null>(null);

    const checkIfSupported = useCallback(
        function(article: IHistory): React.ReactNode {
            if (__BROWSER__) {
                if (!ArticleUtils.isSupported(languageConsumer.value, article.titre_en)
                    || !ArticleUtils.isSupported(languageConsumer.value, article.description_en)) {
                    return <WarningObsoleteMessageBar t={ translationConsumer.t } />
                }
            }

            return null;
        }, []
    );

    const processLinkGeneration = useCallback(
        function (linkNodes: Array<HTMLElement>): void {
            linkNodes.forEach(linkNode => {
                linkNode.setAttribute("target", "_blank");

                const logoDownloadLinkNode: HTMLImageElement = document.createElement("img");
                logoDownloadLinkNode.src = pdfLogo;

                linkNode.appendChild(logoDownloadLinkNode);
            });
        }, []
    );

    const mergeLonelyPictures = useCallback(
        function (imageNodes: Array<HTMLElement>): void {
            const pictures = new Array<HTMLImageElement>();

            imageNodes.forEach(imageNode => {
                imageNode.classList.add(css.inactive);

                pictures.push(imageNode as HTMLImageElement);
            });

            setPictures(pictures);
        }, []
    );

    useEffect(
        function () {
            processLinkGeneration(
                Array.from(
                    containerRef.current?.getElementsByTagName("a") ?? []
                )
            )

            if (props.event.diaporama) {
                mergeLonelyPictures(
                    Array.from(
                        containerRef.current?.getElementsByTagName("img") ?? []
                    )
                );
            } else {
                Array.from(
                    containerRef.current?.getElementsByTagName("img") ?? []
                ).forEach(imageNode => {
                    imageNode.addEventListener("click", function(event: MouseEvent): void {
                        setSelectedPicture((event?.target as unknown as HTMLImageElement)?.src);
                    });
                });
            }
        }, [props.event]
    );

    return (
        <div className={ css.wrapper }>
            {
                checkIfSupported(props.event)
            }

            <Navigation items={
                [
                    { i18nKey: "header.nav.home", target: "/" },
                    { i18nKey: "header.nav.events", target: "/events" }
                ] } />


            <div className="ms-Grid">
                <div className="ms-Grid-row">
                    <h1 className={ css.title }>{ ArticleUtils.checkLanguageSupport(languageConsumer.value, props.event.titre_en) == LanguageSupport.English ? props.event.titre_en : props.event.titre_fr }</h1>

                    <Stack tokens={{ childrenGap: 12 }}>
                        <Separator styles={{ root: [{ selectors: { "::before": { background: "var(--default-color)" } } }] }}>{ __BROWSER__ && moment(props.event.date).locale(languageConsumer.value).format("DD MMM YYYY") }</Separator>
                    </Stack>
                </div>
                <div className={ ["ms-Grid-row", css.container].join(" ") } ref={ containerRef }>
                    <ReactMarkdown
                        source={ ArticleUtils.checkLanguageSupport(languageConsumer.value, props.event.description_en) == LanguageSupport.English ? props.event.description_en : props.event.description_fr }
                        escapeHtml={ false } />

                    {
                        selectedPicture
                            && <Lightbox mainSrc={ selectedPicture } onCloseRequest={ () => setSelectedPicture(null) } />
                    }
                </div>
            </div>

            {
                __BROWSER__
                    && props.event.diaporama
                        && <ArticleCarousel pictures={ pictures } isMobile={ isMobile } width={ window.innerWidth - 66 } />
            }
        </div>
    );
}
