import React from "react";
import { asyncComponent } from "react-async-component";

import loader from "../../assets/loader.svg";
import css from "./contact.module.css";
import { TranslationContext, LanguageContext, ConfigurationContext } from "../../providers";
import { Translation } from "../../components/Translation";
import SocialNetwork from "../../components/SocialNetwork";
import Title from "../../components/Title";

const AsyncMap = asyncComponent({
    resolve: () => import("../../components/Map"),
    LoadingComponent: () => (
        <div className={ css["loader-container"] }>
            <img src={ loader } />
        </div>
    )
});

export default function Contact() {
    const marker = {
        width: "100%",
        height: 400,
        latitude: 49.2541198952105,
        longitude: 3.9311347530768703,
        zoom: 14
    };

    return (
        <ConfigurationContext.Consumer>
            {
                configurationConsumer => (
                    <TranslationContext.Consumer>
                        {
                            translationConsumer => (
                                <LanguageContext.Consumer>
                                    {
                                        languageConsumer => (
                                            <div className={ ["ms-Grid", css.wrapper].join(" ") }>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                                        <Title text={ translationConsumer.get("footer.nav.contactUs" )} />

                                                        <p>
                                                            <span>
                                                                <Translation t={ translationConsumer.t } i18nKey="contactUs.content_1" />
                                                            </span>
                                                            <span>{ languageConsumer.value.startsWith("en") ? configurationConsumer.boutique.ouverture_en : configurationConsumer.boutique.ouverture_fr }</span><br /><br />

                                                            <span>
                                                                <Translation t={ translationConsumer.t } i18nKey="contactUs.content_2" />
                                                            </span><br />

                                                            <span>
                                                                <Translation t={ translationConsumer.t } i18nKey="contactUs.latitude" />
                                                            </span>
                                                            <span> : </span>
                                                            <span>{ marker.latitude }</span><br />

                                                            <span>
                                                                <Translation t={ translationConsumer.t } i18nKey="contactUs.longitude" />
                                                            </span>
                                                            <span> : </span>
                                                            <span>{ marker.longitude }</span><br /><br />

                                                            <Translation t={ translationConsumer.t } i18nKey="contactUs.content_3" />
                                                        </p>
                                                        <p>
                                                            <strong>
                                                                <a href={ "mailto:" + configurationConsumer.contact.default_email }>{ configurationConsumer.contact.default_email }</a>
                                                            </strong>
                                                        </p>

                                                        <div>
                                                            <SocialNetwork />
                                                        </div>
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8">
                                                        <AsyncMap marker={ marker } />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                </LanguageContext.Consumer>
                            )
                        }
                    </TranslationContext.Consumer>
                )
            }
        </ConfigurationContext.Consumer>
    );
}
