import React, { useCallback, useState, useContext } from "react";
import moment from "moment";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react/lib-commonjs/Dropdown";
import { MaskedTextField } from "office-ui-fabric-react/lib-commonjs/TextField";
import { PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";

import css from "./filter-by-date.module.css";
import HttpUtils from "../../../helpers/HttpUtils";
import ArticleUtils from "../../../helpers/ArticleUtils";
import constantes from "../../../constantes";
import { LanguageContext, TranslationContext } from "../../../providers";

enum Event {
    Past,
    Future,
    Custom
}

interface IFilterByDateProps {
    section: string;
    field: string;
    onUrlChange?: (url: string) => void;
}

export default function FilterByDate(props: IFilterByDateProps) {
    const translationConsumer = useContext(TranslationContext);

    const [itemKeyBits, setItemKeyBits] = useState(Event.Future);

    const defaultMonth: string = moment().format("MM");
    const defaultYear: string = moment().format("YYYY");
    const [customMonth, setCustomMonth] = useState(defaultMonth);
    const [customYear, setCustomYear] = useState(defaultYear);

    const fetchMonthsByLocale = useCallback(
        function(locale: string): Array<string> {
            return moment().locale(locale).localeData().months();
        }, []
    );

    const buildUrlBetweenTwoDates = useCallback(
        function(dateA: Date, dateB: Date, order: "ASC" | "DESC"): string {
            return HttpUtils.formattingSearchCriteria(
                constantes.api.origin,
                props.section,
                [
                    {keywords: ["disponible"], criteria: "true"},
                    {keywords: [props.field, "gte"], criteria: dateA.toISOString()},
                    {keywords: [props.field, "lte"], criteria: dateB.toISOString()},
                    {keywords: ["_sort"], criteria: `${props.field}:${order}`}
                ]
            );
        }, []
    );

    const buildUrlForDate = useCallback(
        function(date: Date, order: "ASC" | "DESC"): string {
            let criterias = [];
            if (order == "ASC") {
                criterias = [
                    {keywords: ["disponible"], criteria: "true"},
                    {keywords: [props.field, "gte"], criteria: date.toISOString()},
                    {keywords: ["_sort"], criteria: `${props.field}:${order}`}
                ];
            } else {
                criterias = [
                    {keywords: ["disponible"], criteria: "true"},
                    {keywords: [props.field, "lte"], criteria: date.toISOString()},
                    {keywords: ["_sort"], criteria: `${props.field}:${order}`}
                ];
            }

            return HttpUtils.formattingSearchCriteria(
                constantes.api.origin,
                props.section,
                criterias
            );
        }, []
    );

    const handlePastEvent = useCallback(
        function(): string {
            const todayMidnightDate: Date = ArticleUtils.getMidnightDate(new Date());
            return buildUrlForDate(todayMidnightDate, "DESC");
        }, []
    );

    const handleFutureEvent = useCallback(
        function(): string {
            const todayMidnightDate: Date = ArticleUtils.getMidnightDate(new Date());
            return buildUrlForDate(todayMidnightDate, "ASC");
        }, []
    );

    const handleFiltrationMethod = useCallback(
        function(_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) {
            setItemKeyBits((item?.key as number) ?? 0);

            switch (item?.key) {
                case Event.Past:
                    props?.onUrlChange?.(handlePastEvent());
                    break;
                case Event.Future:
                    props?.onUrlChange?.(handleFutureEvent());
                    break;
            }
        }, []
    );

    const handleCustomMonth = useCallback(
        function(_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) {
            setCustomMonth((item?.key as string) || defaultMonth);
        }, []
    );

    const handleCustomYear = useCallback(
        function(_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, item?: string) {
            setCustomYear((item as string) || defaultYear);
        }, []
    );

    const handleCustomFilter = useCallback(
        function() {
            const firstDayOfMonth: Date = moment(`${customMonth} ${customYear}`, "MM YYYY").startOf("months").toDate();
            const lastDayOfMonth: Date = moment(`${customMonth} ${customYear}`, "MM YYYY").endOf("months").toDate();
            
            props?.onUrlChange?.(buildUrlBetweenTwoDates(firstDayOfMonth, lastDayOfMonth, "ASC"));
        }, [customMonth, customYear]
    );

    const filtrationMethods = [
        {key: Event.Past, text: translationConsumer.get("filters.filterByDate.past")},
        {key: Event.Future, text: translationConsumer.get("filters.filterByDate.future")},
        {key: Event.Custom, text: translationConsumer.get("filters.filterByDate.customDate")}
    ];

    return (
        <div className={ css.wrapper }>
            <div className="ms-Grid">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg4">
                        <Dropdown placeholder={ translationConsumer.get("filters.labels.noOptionSelected") } label={ translationConsumer.get("filters.labels.filterOn") } options={ filtrationMethods } onChange={ handleFiltrationMethod } style={{ width: 220 }} defaultSelectedKey={ itemKeyBits } />
                    </div>

                    {
                        ((itemKeyBits & Event.Custom) == Event.Custom)
                            && (
                                <React.Fragment>
                                    <div className="ms-Grid-col ms-lg4">
                                        <div className="ms-Grid">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-lg7">
                                                    <LanguageContext.Consumer>
                                                        {
                                                            languageConsumer => (
                                                                <Dropdown placeholder={ translationConsumer.get("filters.filterByDate.labels.noOptionSelected") } label={ translationConsumer.get("filters.filterByDate.labels.whichMonth") } options={ fetchMonthsByLocale(languageConsumer.value).map(month => ({ key: moment(month, "MMMM", languageConsumer.value).format("MM"), text: month })) } selectedKey={ customMonth } onChange={ handleCustomMonth } style={{ width: 220 }} />
                                                            )
                                                        }
                                                    </LanguageContext.Consumer>
                                                </div>
                                                <div className="ms-Grid-col ms-lg5">
                                                    <MaskedTextField label={ translationConsumer.get("filters.filterByDate.labels.whatYear") } mask="****" maskFormat={{ "*": /[0-9]/ }} value={ customYear } onChange={ handleCustomYear } styles={{ fieldGroup: { width: 50 } }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-lg4">
                                        <PrimaryButton text="Filtrer" onClick={ handleCustomFilter } allowDisabledFocus style={{ marginTop: "26px" }} />
                                    </div>
                                </React.Fragment>
                            )
                    }
                </div>
            </div>
        </div>
    );
}
