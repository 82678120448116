const routes = {
    home: "/",
    events: "/events",
    event_details: "/events/:id",
    history: "/history-of-the-circuit",
    history_details: "/history-of-the-circuit/:id",
    restauration: "/restauration",
    restauration_details: "/restauration/:id",
    audio_tour: "/audio-tour",
    legalNotice: "/legal-notice",
    contact: "/contact",
    search: "/search/:keywords",
    store: "/store",
    sponsors: "/sponsors",
    membership: "/membership",
    successPaymentVerification: "/payment/success",
    failurePaymentVerification: "/payment/failure"
};

export function getRoute(
    path: string,
    params?: { [key: string]: string | number },
    routesConfig: any = routes
) {
    return path.split(".").reduce((routeBranch: any, pathItem: string) => {
        if (routeBranch && routeBranch[pathItem]) {
            const route = routeBranch[pathItem];
            if (typeof route === "string") {
                if (!params || typeof params === "undefined") {
                    return route;
                }

                return Object.entries(params).reduce((replaced, [key, value]) => {
                    return replaced.replace(`:${key}`, String(value));
                }, route);
            }
            
            return routeBranch[pathItem];
        }
    }, routesConfig);
}

export default routes;
