export default {
    mapGl: {
        accessToken: "pk.eyJ1IjoiYW1pcy1kdS1jaXJjdWl0LWRlLWd1ZXV4IiwiYSI6ImNrYTZyNG45dTA5cDYydW1veXUwdGN4ZGcifQ.cxi-N5PHvi12sLSIjibdKQ"
    },
    searchParams: "keywords",
    sections: {
        event: {
            color: "#B20D30",
            icon: "ms-Icon--Calendar"
        },
        restauration: {
            color: "#3F784C",
            icon: "ms-Icon--Articles"
        },
        guidedAudio: {
            color: "#3F84E5",
            icon: "ms-Icon--Volume3"
        },
        history: {
            color: "#C17817",
            icon: "ms-Icon--History"
        },
        store:{
            color: "pink",
            icon: "ms-Icon--ShoppingCartSolid"
        }
    },
    api: {
        origin: __BROWSER__ ? window.__API_LOCATION__ : ""
    }
};
