import React, { useContext, useState } from "react";

import css from "./timeline.module.css";
import ReactUtils from "../../helpers/ReactUtils";
import { useFetch } from "../../helpers/hooks";
import { TranslationContext } from "../../providers";
import IArticle from "../../models/IArticle";
import Loader from "../Loader";
import CustomDialog from "../CustomDialog";
import TimeLineItem from "../TimelineItem";

interface ITimelineProps {
    articles?: Array<IArticle>;
    url?: string;
    withIcons: boolean;
    children?: React.ReactNode;
}

export default function Timeline(props: ITimelineProps) {
    const translationConsumer = useContext(TranslationContext);
    const [url, setUrl] = useState<string>(props.url ?? "");
    const { isLoading, data: articles, error } = (function() {
        if (props.articles) {
            return { isLoading: false, data: props.articles, error: null };
        } else {
            if (__BROWSER__) {
                return useFetch<Array<IArticle>>(
                    url,
                    undefined,
                    [url]
                );
            }
    
            return { isLoading: false, data: new Array<IArticle>(), error: null };
        }
    })();

    let children: React.ReactNode = null;
    if (props.children != null) {
        children = React.cloneElement(
            React.Children.only(props.children as any),
            {
                onUrlChange: setUrl
            }
        );
    }

    return (
        <div className={ css.wrapper }>
            {
                children
            }

            {
                ReactUtils.either(
                    isLoading,
                    <Loader className={ css["loader-container"] } />,
                    ReactUtils.neither(
                        error == null,
                        <CustomDialog title={ error ?? "" } description={ translationConsumer.get("dialog.technicalError") } />,
                        articles?.map(article => {
                            return (
                                <TimeLineItem key={ `${article.module_id}@${article.id}` } article={ article } withIcons={ props.withIcons } />
                            );
                        })
                    )
                )
            }
        </div>
    );
}
