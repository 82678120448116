import React from "react";

import loaderSvg from "../../assets/loader.svg";

interface ILoaderProps {
    className?: string;
}

export default function Loader (props: ILoaderProps) {
    return (
        <div className={ props.className } >
            <img src={ loaderSvg } />
        </div>
    );
}
