import React, { useCallback, useState, useContext } from "react";
import moment from "moment";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react/lib-commonjs/Dropdown";
import { MaskedTextField } from "office-ui-fabric-react/lib-commonjs/TextField";
import { PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";

import css from "./filter-by-order.module.css";
import HttpUtils from "../../../helpers/HttpUtils";
import constantes from "../../../constantes";
import { TranslationContext } from "../../../providers";

enum Filter {
    ChronologicalOrder,
    Custom
}

interface IFilterByOrderProps {
    field: string;
    section: string;
    onUrlChange?: (url: string) => void;
}

export default function FilterByOrder(props: IFilterByOrderProps) {
    const translationConsumer = useContext(TranslationContext);

    const defaultYear: string = moment().format("YYYY");
    const [itemKeyBits, setItemKeyBits] = useState(Filter.ChronologicalOrder);
    const [customYear, setCustomYear] = useState(defaultYear);

    const buildUrlFilterByChronologicalOrder = useCallback(
        function() {
            return HttpUtils.formattingSearchCriteria(
                constantes.api.origin,
                props.section,
                [
                    {keywords: ["disponible"], criteria: "true"},
                    {keywords: ["_sort"], criteria: `${props.field}:ASC`}
                ]
            );
        }, []
    );

    const buildUrlFilterByCustomYear = useCallback(
        function(year) {
            const firstDayOfYear = moment(year).startOf("year").toISOString();
            const lastDayOfYear = moment(year).endOf("year").toISOString();

            return HttpUtils.formattingSearchCriteria(
                constantes.api.origin,
                props.section,
                [
                    {keywords: ["disponible"], criteria: "true"},
                    {keywords: [props.field, "gte"], criteria: firstDayOfYear},
                    {keywords: [props.field, "lte"], criteria: lastDayOfYear},
                    {keywords: ["_sort"], criteria: `${props.field}:ASC`}
                ]
            );
        }, []
    );

    const handleFiltrationMethod = useCallback(
        function(_: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) {
            setItemKeyBits((item?.key as number) ?? 0);

            switch (item?.key) {
                case Filter.ChronologicalOrder:
                    props?.onUrlChange?.(buildUrlFilterByChronologicalOrder());
                    break;
            }
        }, []
    );

    const handleCustomYear = useCallback(
        function(_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, item?: string) {
            setCustomYear((item as string) || defaultYear);
        }, []
    );

    const handleCustomFilter = useCallback(
        function() {
            if (!customYear.includes("_")) {
                props?.onUrlChange?.(buildUrlFilterByCustomYear(customYear));
            }
        }, [customYear]
    );

    const filtrationMethods = [
        {key: Filter.ChronologicalOrder, text: translationConsumer.get("filters.filterByOrder.chronologicalOrder")},
        {key: Filter.Custom, text: translationConsumer.get("filters.filterByOrder.customYear")}
    ];

    return (
        <div className={ css.wrapper }>
            <div className="ms-Grid">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-lg4">
                        <Dropdown placeholder={ translationConsumer.get("filters.labels.noOptionSelected") } label={ translationConsumer.get("filters.labels.filterOn") } options={ filtrationMethods } onChange={ handleFiltrationMethod } style={{ width: 220 }} defaultSelectedKey={ itemKeyBits } />
                    </div>

                    {
                        itemKeyBits == Filter.Custom
                            && (
                                <React.Fragment>
                                    <div className="ms-Grid-col ms-lg4">
                                        <div className="ms-Grid">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-lg4">
                                                    <MaskedTextField label={ translationConsumer.get("filters.filterByDate.labels.whatYear") } mask="****" maskFormat={{ "*": /[0-9]/ }} value={ customYear } onChange={ handleCustomYear } styles={{ fieldGroup: { width: 50 } }} />
                                                </div>

                                                <div className="ms-Grid-col ms-lg4">
                                                    <PrimaryButton text="Filtrer" onClick={ handleCustomFilter } allowDisabledFocus style={{ marginTop: "26px" }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                    }
                </div>
            </div>
        </div>
    );
}
