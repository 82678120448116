import React from "react";

import Audio from "../../components/GuidedAudio"

export default class GuidedAudio extends React.Component {
    public render(): React.ReactNode {
        return (
            <Audio />
        );
    }
}
