import { produce } from "immer";

import { ActionTypes } from "./actions";
import { Action, AppState } from "./types";

export const initialState = Object.freeze<AppState>({
    locale: "fr_FR"
});

export default function (state: AppState = initialState, action: Action): AppState {
    return produce(state, (draft) => {
        switch (action.type) {
            case ActionTypes.SETLOCALE: {
                draft.locale = action.payload;
                return;
            }
        }
    });
}
