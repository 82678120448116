import React, { useState, useCallback, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";

import css from "./membership.module.css";
import constantes from "../../constantes";
import ReactUtils from "../../helpers/ReactUtils";
import { TranslationContext } from "../../providers";
import IPaybox from "../../models/IPaybox";
import Loader from "../../components/Loader";
import InformationForm, { Type } from "../../components/InformationForm";
import CustomDialog from "../../components/CustomDialog";
import Paybox from "../../components/Paybox";

export default function Membership() {
    const containerElement = useRef(null);
    const [isWaiting, setIsWaiting] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [PBXDataForm, setPBXDataForm] = useState<IPaybox | null>(null);

    const submitHandler = useCallback(
        async function(formData: FormData, type: Type): Promise<void> {
            setIsWaiting(true);

            const headers = new Headers({
                "Content-Type": "application/json"
            });

            let response: Response | null = null;
            if (type === Type.Individual) {
                response = await fetch(`${constantes.api.origin}/adhesions/individual`, {
                    method: "POST",
                    headers,
                    body: JSON.stringify(
                        Object.fromEntries(formData)
                    )
                });
            } else if (type === Type.Company) {
                response = await fetch(`${constantes.api.origin}/adhesions/company`, {
                    method: "POST",
                    headers,
                    body: JSON.stringify(
                        Object.fromEntries(formData)
                    )
                });
            }

            setIsWaiting(false);
            
            if (response?.ok) {
                setSuccess(true);

                const PBXDataForm = await response.json() as IPaybox;
                setPBXDataForm(PBXDataForm);
            } else {
                const errors = (await response?.json()).message;
                setError(errors.join(" / "));
            }
        }, []
    );

    useEffect(
        function() {
            if (isMobile) {
                (containerElement.current as unknown as HTMLElement)?.classList?.add(`${css.mobile}`);
            }
        }, []
    );

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ css.wrapper }>
                        <div ref={ containerElement } className={ css.container }>
                            {
                                ReactUtils.onlyIf(
                                    error != null,
                                    <CustomDialog title={ error ?? "" } description={ translationConsumer.get("dialog.technicalError") } />
                                )
                            }

                            {
                                ReactUtils.neither(
                                    isWaiting || success,
                                    <div className={ css["container-writing-mode"] }>
                                        <InformationForm types={ [Type.Individual, Type.Company] } withSelectionPayment={ true } withAccountAndTaxReceipt={ true } onSubmit={ submitHandler } />
                                    </div>,
                                    <div className={ css.success }>
                                        {
                                            ReactUtils.either(
                                                isWaiting || PBXDataForm == null,
                                                <Loader className={ css["loader-container"] } />,
                                                <div className={ css["paybox-container"] }>
                                                    <Paybox paybox={ PBXDataForm as IPaybox } label="Valider" autoClick={ true } />
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </TranslationContext.Consumer>
    );
}
