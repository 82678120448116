import React from "react";

import css from "./histories.module.css";
import { TranslationContext } from "../../providers";
import HttpUtils from "../../helpers/HttpUtils";
import constantes from "../../constantes";
import Title from "../../components/Title";
import Timeline from "../../components/Timeline";
import FilterByOrder from "../../components/Filters/FilterByOrder";

export default function Events() {
    const defaultUrl: string = HttpUtils.formattingSearchCriteria(
        constantes.api.origin,
        "histoires",
        [
            {keywords: ["disponible"], criteria: "true"},
            {keywords: ["_sort"], criteria: "date:ASC"}
        ]
    );

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ css.wrapper }>
                        <Title icon={ constantes.sections.history.icon } color={ constantes.sections.history.color } text={ translationConsumer.get("header.nav.historyOfTheCircuit") } />

                        <Timeline url={ defaultUrl } withIcons={ false }>
                            <FilterByOrder section="histoires" field="date" />
                        </Timeline>
                    </div>
                )
            }
        </TranslationContext.Consumer>
    )
}
