import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb } from "office-ui-fabric-react/lib-commonjs/Breadcrumb";

import { TranslationContext } from "../../providers";

export interface INavigation {
    i18nKey: string;
    target: string;
}

export default function Navigation(props: { items: Array<INavigation> }) {
    const history = useHistory();

    const handleRedirection = useCallback(
        function (_: React.MouseEvent, item: INavigation) {
            history.push(item.target);
        }, []
    );

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <Breadcrumb
                        // @ts-ignore
                        items={ props.items.map(prop => {
                            return {
                                text: translationConsumer.get(prop.i18nKey),
                                key: prop.i18nKey,
                                target: prop.target,
                                onClick: handleRedirection
                            }
                        }) }
                        styles={{ itemLink: { fontSize: "17px" } }} />
                )
            }
        </TranslationContext.Consumer>
    );
}
