import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { BrowserView, isMobile } from "react-device-detect";

import css from "./timeline-item.module.css";
import { LanguageContext } from "../../providers";
import ArticleUtils, { LanguageSupport } from "../../helpers/ArticleUtils";
import ReactUtils from "../../helpers/ReactUtils";
import constantes from "../../constantes";
import routes from "../../routes";
import IArticle from "../../models/IArticle";
import Date from "./Date";

interface ITimelineProps {
    article: IArticle;
    withIcons: boolean;
}

const MARKDOWN_IMAGE_REGEX: RegExp = /(!\[[^\]]*\]\(.*?\s*(?:"(?:.*[^"])")?\s*\))/g;

export default function TimelineItem(props: ITimelineProps) {
    const getStuffByArticle = useCallback(
        function(article: IArticle): {address: string, icon: string, background: string} | null {
            switch (article.module_id) {
                case "events":
                    return {address: `${routes.events}/${article.id}`, icon: constantes.sections.event.icon, background: constantes.sections.event.color};
                case "historyOfTheCircuit":
                    return {address: `${routes.history}/${article.id}`, icon: constantes.sections.history.icon, background: constantes.sections.history.color};
                case "restauration":
                    return {address: `${routes.restauration}/${article.id}`, icon: constantes.sections.restauration.icon, background: constantes.sections.restauration.color};
                case "audioTour":
                    return {address: `${routes.audio_tour}`, icon: constantes.sections.guidedAudio.icon, background: constantes.sections.guidedAudio.color};
            }

            return null;
        }, []
    );

    return (
        <LanguageContext.Consumer>
            {
                languageConsummer => (
                    <div className={ css.wrapper }>
                        {
                            props.article
                                && (
                                    <div>
                                        <Link to={ getStuffByArticle(props.article)?.address ?? "" } title="">
                                            <div key={ `${props.article.module_id}@${props.article.id}` } className={ ["ms-Grid", css.bloc, css["full-height"]].join(" ") }>
                                                <div className={ ["ms-Grid-row", css["parent-container"], css["full-height"]].join(" ") }>
                                                    <div className={ ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg9", css["full-height"]].join(" ") }>
                                                        <div className={ ["ms-Grid", css["full-height"]].join(" ") }>
                                                            <div className={ ["ms-Grid-row", css["full-height"]].join(" ") }>
                                                                <Date article={ props.article } />

                                                                <div className={ ["ms-Grid-col", "ms-sm8", "ms-md9", "ms-lg9", css["description-container"], css["full-height"]].join(" ") } style={{ maxWidth: (isMobile ? "250px" : "normal") }}>
                                                                    <div className={ css["title"] }>
                                                                        {
                                                                            (function(title: string) {
                                                                                return (
                                                                                    <Link to={ getStuffByArticle(props.article)?.address ?? "" } title={ title }>
                                                                                        {
                                                                                            title
                                                                                        }
                                                                                    </Link>
                                                                                );
                                                                            })(ArticleUtils.checkLanguageSupport(languageConsummer.value, props.article.titre_en) == LanguageSupport.English ? props.article.titre_en : props.article.titre_fr)
                                                                        }
                                                                    </div>
                                                                    <div className={ css["description"] }>
                                                                        {
                                                                            (function(description: string) {
                                                                                return (
                                                                                    <BrowserView>
                                                                                        {
                                                                                            <ReactMarkdown source={ (description ?? "").replace(MARKDOWN_IMAGE_REGEX, "") } />
                                                                                        }
                                                                                    </BrowserView>
                                                                                );
                                                                            })(ArticleUtils.checkLanguageSupport(languageConsummer.value, props.article.description_en) == LanguageSupport.English ? props.article.description_en : props.article.description_fr)
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={ ["ms-Grid-col", "ms-sm12", "ms-md12", "ms-lg3", css["full-height"]].join(" ") }>
                                                        <div className={ ["ms-Grid", css["full-height"]].join(" ") }>
                                                            <div className={ ["ms-Grid-row", css["full-height"]].join(" ") }>
                                                                <div className={ ["ms-Grid-col", (props.withIcons ? "ms-sm9 ms-md9 ms-lg9" : "ms-sm12 ms-md12 ms-lg12"), css["thumbnail-container"], css["full-height"]].join(" ") }>
                                                                    {
                                                                        ReactUtils.onlyIf(
                                                                            props.article.vignette != null,
                                                                            <img src={ `${constantes.api.origin}${props.article.vignette?.url}` } className={ props.article.vignette?.height ?? 0 > 100 ? css.big : css.small } />
                                                                        )
                                                                    }
                                                                </div>
                                                                <div className={ ["ms-Grid-col", (props.withIcons ? "ms-sm3 ms-md3 ms-lg3" : css["hidden-ninja"]), css["icon-container"], css["full-height"]].join(" ") } style={{ backgroundColor: getStuffByArticle(props.article)?.background ?? "" }}>
                                                                    <i className={ ["ms-Icon", css["icon"], getStuffByArticle(props.article)?.icon ?? ""].join(" ") }></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                        }
                    </div>
                )
            }
        </LanguageContext.Consumer>
    );
}
