import React, { useContext, useCallback, useEffect, useState, createRef } from "react";
import moment from "moment";
import { Stack } from "office-ui-fabric-react/lib-commonjs/Stack";
import { Separator } from "office-ui-fabric-react/lib-commonjs/Separator";
import ReactMarkdown from "react-markdown";
import { isMobile } from "react-device-detect";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

import pdfLogo from "../../assets/pdf.png";
import css from "./restauration-details.module.css";
import { LanguageContext, TranslationContext } from "../../providers";
import ArticleUtils, { LanguageSupport, WarningObsoleteMessageBar } from "../../helpers/ArticleUtils";
import IRestauration from "../../models/IRestauration";
import Navigation from "../Navigation";
import ArticleCarousel from "../ArticleCarousel";

interface IRestaurationDetailsProps {
    restauration: IRestauration;
}

export default function RestaurationDetails(props: IRestaurationDetailsProps) {
    const languageConsumer = useContext(LanguageContext);
    const translationConsumer = useContext(TranslationContext);

    const containerRef: React.RefObject<HTMLDivElement> = createRef();
    const [pictures, setPictures] = useState(new Array<HTMLImageElement>());
    const [selectedPicture, setSelectedPicture] = useState<string | null>(null);
    
    const checkIfSupported = useCallback(
        function (article: IRestauration): React.ReactNode {
            if (__BROWSER__) {
                if (!ArticleUtils.isSupported(languageConsumer.value, article.titre_en)
                    || !ArticleUtils.isSupported(languageConsumer.value, article.description_en)) {
                    return <WarningObsoleteMessageBar t={ translationConsumer.t } />
                }
            }
            
            return null;
        }, []
    );

    const processLinkGeneration = useCallback(
        function (linkNodes: Array<HTMLElement>): void {
            linkNodes.forEach(linkNode => {
                linkNode.setAttribute("target", "_blank");
                
                const logoDownloadLinkNode: HTMLImageElement = document.createElement("img");
                logoDownloadLinkNode.src = pdfLogo;

                linkNode.appendChild(logoDownloadLinkNode);
            });
        }, []
    );

    const mergeLonelyPictures = useCallback(
        function (imageNodes: Array<HTMLElement>): void {
            const pictures = new Array<HTMLImageElement>();

            imageNodes.forEach(imageNode => {
                imageNode.classList.add(css.inactive);

                pictures.push(imageNode as HTMLImageElement);
            });

            setPictures(pictures);
        }, []
    );

    useEffect(
        function () {
            processLinkGeneration(
                Array.from(
                    containerRef.current?.getElementsByTagName("a") ?? []
                )
            )

            if (props.restauration.diaporama) {
                mergeLonelyPictures(
                    Array.from(
                        containerRef.current?.getElementsByTagName("img") ?? []
                    )
                );
            } else {
                Array.from(
                    containerRef.current?.getElementsByTagName("img") ?? []
                ).forEach(imageNode => {
                    imageNode.addEventListener("click", function(event: MouseEvent): void {
                        setSelectedPicture((event?.target as unknown as HTMLImageElement)?.src);
                    });
                });
            }
        }, [props.restauration]
    );

    return (
        <div className={ css.wrapper }>
            {
                checkIfSupported(props.restauration)
            }

            <Navigation items={
                [
                    { i18nKey: "header.nav.home", target: "/" },
                    { i18nKey: "header.nav.restauration", target: "/restauration" }
                ] } />

            <div className="ms-Grid">
                <div className={ css.title }>
                    <h1>{ ArticleUtils.checkLanguageSupport(languageConsumer.value, props.restauration.titre_en) == LanguageSupport.English ? props.restauration.titre_en : props.restauration.titre_fr }</h1>

                    <Stack tokens={{ childrenGap: 12 }}>
                        <Separator styles={{ root: [{ selectors: { "::before": { background: "var(--default-color)" } } }] }}>{ __BROWSER__ && moment(props.restauration.date).locale(languageConsumer.value).format("DD MMM YYYY") }</Separator>
                    </Stack>
                </div>
                <div className={ ["ms-Grid-row", css.container].join(" ") } ref={ containerRef }>
                    <ReactMarkdown
                        source={ ArticleUtils.checkLanguageSupport(languageConsumer.value, props.restauration.description_en) == LanguageSupport.English ? props.restauration.description_en : props.restauration.description_fr }
                        escapeHtml={ false } />
                    
                    {
                        selectedPicture
                            && <Lightbox mainSrc={ selectedPicture } onCloseRequest={ () => setSelectedPicture(null) } />
                    }
                </div>
            </div>

            {
                __BROWSER__
                    && props.restauration.diaporama
                        && <ArticleCarousel pictures={ pictures } isMobile={ isMobile } width={ window.innerWidth - 66 } />
            }
        </div>
    );
}
