import React from "react";
import "react-h5-audio-player/lib/styles.css";

import css from "./guided-audio.module.css";
import HttpUtils from "../../helpers/HttpUtils";
import ReactUtils from "../../helpers/ReactUtils";
import { useFetch } from "../../helpers/hooks";
import { TranslationContext } from "../../providers";
import constantes from "../../constantes";
import IVisit from "../../models/IVisit";
import Loader from "../Loader";
import CustomDialog from "../CustomDialog";
import { Translation } from "../Translation";
import Title from "../Title";
import Audio from "../Audio"

export default function GuidedAudio() {
    const { isLoading, data: articles, error } = (function() {
        if (__BROWSER__) {
            return useFetch<Array<IVisit>>(
                HttpUtils.formattingSearchCriteria(
                    constantes.api.origin,
                    "visites",
                    [
                        {keywords: ["disponible"], criteria: "true"},
                        {keywords: ["_sort"], criteria: "ordre:ASC"}
                    ]
                ),
                undefined,
                []
            );
        }

        return { isLoading: false, data: new Array<IVisit>(), error: null };
    })();

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ css.wrapper }>
                        <Title icon={ constantes.sections.guidedAudio.icon } color={ constantes.sections.guidedAudio.color } text={ translationConsumer.get("header.nav.audioTour") } />

                        <blockquote>
                            <Translation t={ translationConsumer.t } i18nKey="audioTour.paillettesExplication" />
                        </blockquote>

                        {
                            ReactUtils.either(
                                isLoading,
                                <Loader className={ css["loader-container"] } />,
                                ReactUtils.neither(
                                    error == null,
                                    <CustomDialog title={ error ?? "" } description={ translationConsumer.get("dialog.technicalError") } />,
                                    articles?.map(visit => {
                                        return <Audio key={ visit.id } visit={ visit } />
                                    })
                                )
                            )
                        }
                    </div>
                )
            }
        </TranslationContext.Consumer>
    )
}
