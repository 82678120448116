import React from "react";
import { TFunction } from "i18next";
import { Trans } from "react-i18next";

interface ITanslationProps {
    t: TFunction;
    i18nKey: string;
}

export function Translation(props: ITanslationProps) {
    return (
        <Trans 
            t={ props.t } 
            i18nKey={ props.i18nKey } />
    );
}
