import React, { useContext } from "react";

import css from "./home.module.css";
import HttpUtils from "../../helpers/HttpUtils";
import ReactUtils from "../../helpers/ReactUtils";
import ArticleUtils from "../../helpers/ArticleUtils";
import { useFetchBatch } from "../../helpers/hooks";
import { TranslationContext } from "../../providers";
import constantes from "../../constantes";
import IArticle from "../../models/IArticle";
import Loader from "../../components/Loader";
import CustomDialog from "../../components/CustomDialog";
import Timeline from "../../components/Timeline";

export default function Home() {
    const translationConsumer = useContext(TranslationContext);

    const { isLoading: isLoadingPrimary, data: dataPrimary, error: errorPrimary } = useFetchBatch<Array<IArticle>>(
        [
            "visites"
        ].map(section => {
            return {
                input: (
                    HttpUtils.formattingSearchCriteria(
                        constantes.api.origin,
                        section,
                        [
                            {keywords: ["disponible"], criteria: "true"},
                            {keywords: ["favoris"], criteria: "true"}
                        ]
                    )
                )
            };
        }),
        []
    );

    const { isLoading: isLoadingSecondary, data: dataSecondary, error: errorSecondary } = useFetchBatch<Array<IArticle>>(
        [
            "histoires",
            "restaurations",
            "evenements"
        ].map(section => {
            return {
                input: (
                    HttpUtils.formattingSearchCriteria(
                        constantes.api.origin,
                        section,
                        [
                            {keywords: ["disponible"], criteria: "true"},
                            {keywords: ["favoris"], criteria: "true"}
                        ]
                    )
                )
            };
        }),
        []
    );

    return (
        ReactUtils.either(
            isLoadingPrimary && isLoadingSecondary,
            <Loader className={ css["loader-container"] } />,
            ReactUtils.neither(
                errorPrimary == null && errorSecondary == null,
                <CustomDialog title={ errorPrimary ?? errorPrimary ?? "" } description={ translationConsumer.get("dialog.technicalError") } />,
                <Timeline articles={
                    ArticleUtils.reverseByDate(
                        dataPrimary.flat().concat(dataSecondary.flat()),
                        (article: IArticle) => article.created_at)
                } withIcons={ true } />
            )
        )
    );
}
