import React, { useContext, useCallback, useState, useMemo } from "react";
import { Dialog, DialogFooter, DialogType } from "office-ui-fabric-react/lib-commonjs/Dialog";
import { PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";

import { TranslationContext } from "../../providers";
import { Translation } from "../Translation";

interface IDialogProps {
    title: string;
    description: string;
}

export default function CustomDialog(props: IDialogProps) {
    const translationConsumer = useContext(TranslationContext);
    const [hideDialog, setHideDialog] = useState<boolean>(false);

    const CustomDialogProps = useMemo(
        function() {
            return {
                isBlocking: false
            };
        }, []
    );

    const handleCloseClick = useCallback(
        function(_: React.MouseEvent<HTMLElement>) {
            setHideDialog(true);
        }, []
    );

    return (
        __BROWSER__
            && (
                <Dialog
                    hidden={ hideDialog }
                    dialogContentProps={{ type: DialogType.normal, title: props.title, subText: props.description }}
                    modalProps={ CustomDialogProps }
                >
                    <DialogFooter>
                        <PrimaryButton onClick={ handleCloseClick }>
                            <Translation t={ translationConsumer.t } i18nKey="dialog.close" />
                        </PrimaryButton>
                    </DialogFooter>
                </Dialog>
            )
            || null
    );
}
