import React, { useContext, useCallback, useState, useEffect, createRef } from "react";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

import css from "./history-details.module.css";
import { LanguageContext, TranslationContext } from "../../providers";
import ArticleUtils, { LanguageSupport, WarningObsoleteMessageBar } from "../../helpers/ArticleUtils";
import IHistory from "../../models/IHistory";
import Navigation from "../Navigation";

interface IHistoryDetailsProps {
    history: IHistory
}

export default function HistoryDetails(props: IHistoryDetailsProps) {
    const languageConsumer = useContext(LanguageContext);
    const translationConsumer = useContext(TranslationContext);

    const containerRef: React.RefObject<HTMLDivElement> = createRef();
    const [selectedPicture, setSelectedPicture] = useState<string | null>(null);

    const checkIfSupported = useCallback(
        function (article: IHistory): React.ReactNode {
            if (__BROWSER__) {
                if (!ArticleUtils.isSupported(languageConsumer.value, article.titre_en)
                    || !ArticleUtils.isSupported(languageConsumer.value, article.description_en)) {
                    return <WarningObsoleteMessageBar t={ translationConsumer.t } />
                }
            }
            
            return null;
        }, []
    );

    useEffect(
        function () {
            Array.from(
                containerRef.current?.getElementsByTagName("img") ?? []
            ).forEach(imageNode => {
                imageNode.addEventListener("click", function(event: MouseEvent): void {
                    setSelectedPicture((event?.target as unknown as HTMLImageElement)?.src);
                });
            });
        }, [props.history]
    );

    return (
        <div className={ css.wrapper }>
            {
                checkIfSupported(props.history)
            }

            <Navigation items={
                [
                    { i18nKey: "header.nav.home", target: "/" },
                    { i18nKey: "header.nav.historyOfTheCircuit", target: "/history-of-the-circuit" }
                ] } />
            
            <article>
                <h1 className={ css.title }>{ ArticleUtils.checkLanguageSupport(languageConsumer.value, props.history.titre_en) == LanguageSupport.English ? props.history.titre_en : props.history.titre_fr }</h1>
                <h1 className={ css.date }><time>{ moment(props.history.date).locale(languageConsumer.value).format("DD MMM YYYY") }</time></h1>

                <section ref={ containerRef }>
                    <ReactMarkdown
                        source={ ArticleUtils.checkLanguageSupport(languageConsumer.value, props.history.description_en) == LanguageSupport.English ? props.history.description_en : props.history.description_fr }
                        escapeHtml={ false } />
                    
                    {
                        selectedPicture
                            && <Lightbox mainSrc={ selectedPicture } onCloseRequest={ () => setSelectedPicture(null) } />
                    }
                </section>
            </article>
        </div>
    );
}
