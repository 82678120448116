import React, { useCallback } from "react";
import moment from "moment";
import "moment/locale/fr";

import css from "./date.module.css";
import { LanguageContext, TranslationContext } from "../../../providers";
import IArticle from "../../../models/IArticle";

interface IDateProps {
    article: IArticle;
}

export default function Date(props: IDateProps) {
    const fnGetDate = useCallback(
        function(article: IArticle) {
            if (["events", "restauration", "historyOfTheCircuit"].includes(article.module_id)) {
                return article.date;
            }

            return article.created_at;
        }, []
    );

    const showDayPriority = useCallback(
        function(article: IArticle): React.ReactElement {
            return <LanguageContext.Consumer>
                {
                    languageConsumer => {
                        return <React.Fragment>
                            <div className={ css["calendar-day"] }>{ moment(fnGetDate(article)).format("YYYY") }</div>
                            <hr className={ css["calendar-separator"] } />
                            <div className={ css["calendar-month"] }>
                                <span>{ moment(fnGetDate(article)).locale(languageConsumer.value).format("MMM") }</span>&nbsp;
                                <small>{ moment(fnGetDate(article)).locale(languageConsumer.value).format("DD") }</small>
                            </div>
                        </React.Fragment>;
                    }
                }
            </LanguageContext.Consumer>
        }, []
    );

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ css.wrapper }>
                        <div className={ ["ms-Grid-col", "ms-sm4", "ms-md4", "ms-lg3", css["calendar-container"], css["full-height"]].join(" ") }>
                            <div className={ [css.calendar, css["full-height"]].join(" ") }>
                                {
                                    props.article.favoris && <i className={ ["ms-Icon", "ms-Icon--HeartFill", css.favorite].join(" ") } title={ translationConsumer.get("general.favorite") } />
                                }
                                
                                {
                                    showDayPriority(props.article)
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </TranslationContext.Consumer>
    );
}
