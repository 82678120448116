import React from "react";

import css from "./legal-notice.module.css";
import { TranslationContext, ConfigurationContext } from "../../providers";
import { Translation } from "../../components/Translation";
import Title from "../../components/Title";

export default function LegalNotice() {
    return (
        <ConfigurationContext.Consumer>
            {
                configurationConsumer => (
                    <TranslationContext.Consumer>
                        {
                            translationConsumer => (
                                <div className={ css.wrapper }>
                                    <Title text={ translationConsumer.get("footer.legalNotice") } />

                                    <div>
                                        <Translation t={ translationConsumer.t } i18nKey="legalNotice.inApplicationOfThelaw1" />
                                        <span>,</span>
                                        <p>
                                            <span>{ configurationConsumer.titre } </span>
                                            <Translation t={ translationConsumer.t } i18nKey="legalNotice.inApplicationOfThelaw2" />
                                            <span> : </span>
                                        </p>
                                    </div><br />

                                    <div>
                                        <strong>
                                            <Translation t={ translationConsumer.t } i18nKey="legalNotice.siteEditor" />
                                            <span> : </span>
                                        </strong><br />
                                        <span>
                                            <Translation t={ translationConsumer.t } i18nKey="legalNotice.socialReason" />
                                            <span> : </span>
                                            <span>{ configurationConsumer.titre }</span>
                                        </span><br />
                                        <span>
                                            <Translation t={ translationConsumer.t } i18nKey="general.address" />
                                            <span> : </span>
                                            <span>14 rue du Moutier - 51390 Gueux</span>
                                        </span><br />
                                        <span>
                                            <Translation t={ translationConsumer.t } i18nKey="legalNotice.publicationManager" />
                                            <span> : </span>
                                            <span>{ configurationConsumer.contact.responsable_publication }</span>
                                        </span><br /><br />
                                        
                                        <strong>
                                            <a href={ "mailto:" + configurationConsumer.contact.responsable_publication_email }>
                                                <Translation t={ translationConsumer.t } i18nKey="general.sendEmail" />
                                            </a>
                                        </strong>
                                    </div><br />

                                    <div>
                                        <strong>
                                            <Translation t={ translationConsumer.t } i18nKey="legalNotice.siteDesigners" />
                                            <span> : </span>
                                        </strong><br />

                                        <span>Alexis DELÉE</span><br />
                                        <span>Arthur PÉCHAYRE</span><br />
                                        <span>Maxence CADIOT</span><br />
                                        <span>ESGI (École Supérieure de Génie Informatique)</span><br />
                                        <span>242 Rue du Faubourg Saint-Antoine</span><br />
                                        <span>75012 Paris</span>
                                    </div><br />

                                    <div>
                                        <strong>
                                            <Translation t={ translationConsumer.t } i18nKey="legalNotice.websiteHost" />
                                            <span> : </span>
                                        </strong><br />

                                        <strong>
                                            <a href="https://www.ovh.com" target="blank">SAS OVH</a><br />
                                        </strong>
                                            
                                        <span>2 rue Kellermann</span><br />
                                        <span>59100 Roubaix</span><br />
                                        <span>France</span>
                                    </div>
                                </div>
                            )
                        }
                    </TranslationContext.Consumer>
                )
            }
        </ConfigurationContext.Consumer>
    );
}
