import React, { useCallback, useState, useContext } from "react";
import { Link } from "react-router-dom";

import loader from "../../assets/loader.svg";
import css from "./search.module.css";
import { TranslationContext } from "../../providers";
import { Translation } from "../../components/Translation";
import routes from "../../routes";
import constantes from "../../constantes";
import { LanguageContext } from "../../providers";
import HttpUtils from "../../helpers/HttpUtils";
import ArticleUtils, { LanguageSupport } from "../../helpers/ArticleUtils";
import IArticle from "../../models/IArticle";

export default function Search(props: any) {
    const locale = useContext(LanguageContext);
    const [isInit, setIsInit] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const [articles, setArticles] = useState(new Array<IArticle>());
    const keywords: string = props.match.params.keywords;

    const buildResultTable = useCallback(
        function(result: IArticle, locale: string, sectionRoute: string, section: {icon: string, background: string}) {
            return (
                <Link to={ sectionRoute } title={ ArticleUtils.checkLanguageSupport(locale, result.titre_en) == LanguageSupport.English ? result.titre_en : result.titre_fr }>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    {
                                        ArticleUtils.isSupported(locale, result.titre_en)
                                            || <i className={ ["ms-Icon", "ms-Icon--Warning12", css.blink].join(" ") }>
                                                <small>FRENCH ONLY</small>
                                            </i>
                                    }
                                </td>
                                <td style={{ backgroundColor: section.background }}>
                                    <i className={ ["ms-Icon", section.icon].join(" ") }></i>
                                </td>
                                <td>
                                    {
                                        ArticleUtils.checkLanguageSupport(locale, result.titre_en) == LanguageSupport.English
                                            && <span>{ result.titre_en }</span>
                                            || <span>{ result.titre_fr }</span>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Link>
            );
        }, []
    );

    const showResultByLocale = useCallback(
        function(result: IArticle, locale: string): React.ReactNode {
            switch (result.module_id) {
                case "events":
                    return buildResultTable(result, locale, `${routes.events}/${result.id}`, {icon: constantes.sections.event.icon, background: constantes.sections.event.color});
                case "historyOfTheCircuit":
                    return buildResultTable(result, locale, `${routes.history}/${result.id}`, {icon: constantes.sections.history.icon, background: constantes.sections.history.color});
                case "restauration":
                    return buildResultTable(result, locale, `${routes.restauration}/${result.id}`, {icon: constantes.sections.restauration.icon, background: constantes.sections.restauration.color});
                case "audioTour":
                    return buildResultTable(result, locale, `${routes.audio_tour}`, {icon: constantes.sections.guidedAudio.icon, background: constantes.sections.guidedAudio.color});
            }
        }, []
    );

    (async () => {
        if (!isInit && __BROWSER__) {
            setIsInit(true);
    
            const articles: Array<Array<IArticle>> = await HttpUtils.batchFetch<Array<IArticle>>(
                [
                    "evenements",
                    "histoires",
                    "restaurations",
                    "visites"
                ].map(section => {
                    return HttpUtils.formattingSearchCriteria(
                        constantes.api.origin,
                        section,
                        [{keywords: ["_q"], criteria: keywords}]
                    );
                })
            );
    
            setArticles(articles.flat().filter(result => result.disponible));
            setLoaded(true);
        }
    })();

    return (
        <TranslationContext.Consumer>
            {
                translationConsumer => (
                    <div className={ css.wrapper }>
                        <h1>
                            <span>
                                <Translation t={ translationConsumer.t } i18nKey="search.resultsFor" />
                            </span>

                            &nbsp;

                            <q>{ keywords }</q>
                        </h1>

                        <ul>
                            {
                                isLoaded
                                    && (
                                        articles.map(article => {
                                            return (
                                                <li key={ `${article.module_id}@${article.id}` }>
                                                    {
                                                        showResultByLocale(article, locale.value)
                                                    }
                                                </li>
                                            )
                                        })
                                    )
                                    || (
                                        <div className={ css["loader-container"] }>
                                            <img src={ loader } />
                                        </div>
                                    )
                            }
                        </ul>
                    </div>
                )
            }
        </TranslationContext.Consumer>
    );
}
