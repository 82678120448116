import React from "react";

import css from "./title.module.css";

interface ITitleProps {
    text: string;
    icon?: string;
    color?: string;
    style?: any;
}

export default function Title(props: ITitleProps) {
    const color: string = props.color ?? "#616161";

    return (
        <div className={ css.wrapper } style={ props?.style ?? {} }>
            <h1 style={{ borderBottom: `1px solid ${color}`, borderLeft: `1px solid ${color}` }}>
                {
                    props?.icon?.length
                        && <i className={ ["ms-Icon", props?.icon].join(" ") } style={{ backgroundColor: color }} />
                }

                <span>{ props.text }</span>
            </h1>
        </div>
    );
}
